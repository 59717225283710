import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import HilyteMain from '../../../Assets/Images/SelectedWork/Hilyte/Hilyte.jpeg';
import Hilyte1 from '../../../Assets/Images/SelectedWork/Hilyte/1.jpeg';
import Hilyte2 from '../../../Assets/Images/SelectedWork/Hilyte/2.jpeg';
import Hilyte3 from '../../../Assets/Images/SelectedWork/Hilyte/3.jpeg';
import Hilyte4 from '../../../Assets/Images/SelectedWork/Hilyte/4.jpeg';
const Hilyte = () => {

   const caseStudyData = {
      title: 'Hilyte',
      content: 'HiLyte is a Swiss-based startup that aims at providing cheap clean energy for people who still use kerosene for lighting. The hiLyte solutions produce enough power to both charge phone and give light to a room for one night. While the R&D team is working from Neuchâtel, the Tanzanian branch was created to produce and distribute locally. This enables the team to build a product that fits completely with the end-users.',
      galleryImages: [
         { url: Hilyte1 },
         { url: Hilyte2 },
         { url: Hilyte3 },
         { url: Hilyte4 },
      ],
      workDetails: 'Team Hilyte went to to the field to see the reality, and experience the problem of the people comeback they had a concept in their mind which we helped them bring into reality by deploying our expertise and providing them additional service, prototyping, and pilot testing of the product before it was ready to help the people in Tanzania. We provided them aid in helping the people in need because that what we believe serves those first who wants to bring a positive change in someone else life.',
      outCome: 'In 2020, two different technologies are being tested to achieve our goal of enabling all people to get clean light and charge their phones at home. One of them uses its own battery technology while the other is a wise mix of solar and electronics, but both of them emphasize decentralization and empowering the rural people. as the people who know best, their needs are the rural people themselves, the company works on decentralizing at most, by recruiting agents in each village who are the contact between rural customers and the company itself. The discussions between those agents and our Tanzanian staff is what drives our product development.',
      year: '2019',
      client: 'Hilyte',
      services: 'Firmware Support, Mobile application Development, Embedded Development',
      keyExpertise: 'Firmware & Embedded Development, Web & Mobile application Development, Manufacturing Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${HilyteMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Hilyte;
