import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import BlazarMain from '../../../Assets/Images/SelectedWork/Blazar/blazar.jpeg';
import Blazar1 from '../../../Assets/Images/SelectedWork/Blazar/1.jpeg';
import Blazar2 from '../../../Assets/Images/SelectedWork/Blazar/2.jpeg';
import Blazar3 from '../../../Assets/Images/SelectedWork/Blazar/3.jpeg';
import Blazar4 from '../../../Assets/Images/SelectedWork/Blazar/4.jpeg';

const Blazar = () => {

   const caseStudyData = {
      title: 'Blazar',
      content: 'Phone to Phone charge or trader data at the speed of 480 Mbps, not only the phone but the user can connect it with the fitness bands, earbuds, smartwatch, wireless headphones, and any other device that needs to charge. It is a product design to save the user in the time of battery crisis, a very easy to use and convenient. It saves a lot of time as the time is essential at this fast pace life we all lead, saving time is a top priority of everyone, and blazar help people make it happen with the help of technology',
      galleryImages: [
         { url: Blazar1 },
         { url: Blazar2 },
         { url: Blazar3 },
         { url: Blazar4 },
      ],
      workDetails: `The society has been rapidly changed with the evolution of technology. Before the appearance of modern technology, life was burdensome and everyday chores consumed a good amount of our time which we all cloud have utilized somewhere else. Taking that into consideration, Blazar had an idea in their mind, a 2-in -1 phone to phone charging USB cable.The duel cable comes with both USB and a Micro- B port in an exceedingly single unit.With our agile expertise in engineering, we helped Blazar the 2 -in -1 phone to phone charging USB cable allows a better way of charging your devices during an emergency.because of the convenience and efficiency provided by Blazar, your life will surely improve significantly.The cable is of appropriate length and straightforward to use.`,
      outCome: 'Blazar aims to design more products to make things convenient for people at this fast pace life and eliminate any sort of crisis people face with the use of technology. The product indigenously crafted by our team and Blazar is a huge part of the Make-In-India initiative and that we are so proud to contribute our immense effort.',
      year: '2019',
      client: 'Blazar',
      services: 'Rapid Experiential Prototyping, Firmware Support, Mobile application Development',
      keyExpertise: 'Firmware & Embedded Development, Mechanical Engineering, Mobile application Development, Manufacturing Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${BlazarMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Blazar;
