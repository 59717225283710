import React from 'react'
import Networking from '../../Assets/Images/svg/networking.svg';
import Robot from '../../Assets/Images/svg/robot.svg';
import Wireless from '../../Assets/Images/svg/wireless.svg';
import ClientReviews from '../../Components/ClientReviews/ClientReviews';

const Services = () => {

   const DataArr = [
      {
         title: "Blockchain and Cryptocurrency",
         image: Networking,
         desc: "Blockchain is a disruptive technological transformation akin to the advent of the internet for how businesses transact with one another. From tracking to transferring money, Smart contracts for Insurance, supply chain, and healthcare, Blockchain is transforming every aspect of the business. It’s not limited to any one industry as it spans across industries and applications.",
      },
      {
         title: "AI & Machine learning",
         image: Robot,
         desc: "Artificial intelligence can feel extremely complicated. Many business and technology leaders aren’t sure where to start. In addition, machine learning projects can be slow and expensive to implement. We use cutting edge machine learning and artificial intelligence to rapidly and repeatedly deliver you insights and advantages from your data. Caliente iTech has a simple mission: make machine learning and AI much more accessible, faster, and more efficient",
      },
      {
         title: "Internet of Things",
         image: Wireless,
         desc: "The space between the physical and digital spheres is fast diminishing in today’s hyper-connected world, spawning an era of true convergence, driven in part by the rapid proliferation of the Internet of Things (IoT). Our IoT consultants define your implementation strategy and application roadmap to build secure IoT solutions. We leverage IoT expertise to connect assets, establish real-time communication with the cloud, and drive analytics for intelligent decision-making.",
      },
      {
         title: "Software as a service",
         image: Wireless,
         desc: "Salesforce is a great example of Software as a Service. Due to the benefits of extensive easiness and fastness of installation late charges, the SAAS platform is the best candidate for a market. We provide all solutions for SAAS solutions.",
      },
      {
         title: "AR & VR",
         image: Wireless,
         desc: "Virtual Reality and Augmented Reality are a powerful medium that allows the business to engage with customers like never before. Our services can guide you through the complexities of that medium and help your business to strategically leverage Virtual, Augmented, and Mixed Reality to gain a competitive edge.",
      },
      {
         title: "Product Design",
         image: Wireless,
         desc: "From business to design to development, there are creative and technical skills involved in creating new products. But there’s also critical thinking, design thinking, and strategic thinking operating under the hood. That’s where digital product design comes in. It’s an iterative process used to solve problems through a multi-faceted lens with an iterative development process. We are a strategic design and innovation partner for your business.",
      },
      {
         title: "ERP & CRM",
         image: Wireless,
         desc: "The world of ERP is often seen as an ancient world dominated by many big vendors. We are David challenging this Goliaths in this field. We have developed unique methodologies that allow our clients to undertake ERP and business change programs using structured processes that maximize the likelihood of project success by promoting business ownership, minimizing risk, and controlling cost while ensuring that planned business benefits are realized.",
      },
      {
         title: "Big Data & Analytics",
         image: Wireless,
         desc: "Big Data’s potential to yield key insights from business information is well known. However, it is equally well acknowledged that only a small percentage of this data gets analyzed. For businesses, such as yours, this means missing out on real-time insights from social media, machine logs, sensors, and other data sources in the absence of qualified Big Data analytics experts. Caliente iTech turns technology into business outcomes by delivering information management, business intelligence, and analytic solutions under one umbrella.",
      },

   ];

   return (
      <>
         <div className="relative flex justify-center items-center overflow-hidden px-8 pt-10 sm:pt-14">
            <div className="text-center max-w-7xl">
               <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                  <span className="text-3xl sm:text-4xl tracking-tight text-white">
                     Explore <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">Our  Services</span>
                  </span>
                  <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                     Caliente iTech team speeds up the working process by deploying smart techniques, coordinating with production and business strategist.
                  </p>
               </div>

               <div className="grid md:grid-cols-3 gap-x-14 mt-10" data-aos="fade-up" data-aos-duration="900">
                  {DataArr?.map((data, index) => (
                     <div className="flex flex-col text-start mt-10 md:mt-14" key={index}>
                        <img src={data?.image} className='float-left w-12' alt={`Services ${index}`} />

                        <p className="mt-3 text-2xl tracking-wide font-medium text-white">
                           {data?.title}
                        </p>
                        <p className="mt-5 text-base leading-7 text-white-light">
                           {data?.desc}
                        </p>
                     </div>
                  ))}
               </div>

            </div>
         </div>
         <ClientReviews />
      </>
   )
}

export default Services