import React from 'react'
import Brainstorming from '../../Assets/Images/svg/brainstorming.svg';
import Binaryfile from '../../Assets/Images/svg/binary-file.svg';
import Setting from '../../Assets/Images/svg/setting.svg';
import Ideabulb from '../../Assets/Images/svg/idea-bulb.svg';
import ClientReviews from '../../Components/ClientReviews/ClientReviews';
import Platforms from './Platforms';

const Expertise = () => {

   const DataArr = [
      {
         image: Brainstorming,
         title: 'Embedded Software Development',
         list: [
            'Linux device drivers and application firmware',
            'Secure firmware upgrade design/development',
            'Secure IoT communication protocol design/development',
            'Factory programming/serialization design/development',
         ],
      },
      {
         image: Binaryfile,
         title: 'Application Software Development',
         list: [
            'iOS/Android application development',
            'iOS/Android hardware accessory integration',
            'Mobile usability testing',
            'Cloud architecture design',
         ],
      },
      {
         image: Setting,
         title: 'Defi Development',
         list: [
            'Decentralized Exchange Development',
            'Staking Development',
            'Yield Farming Development',
            'Lending/ Borrowing Platform Development',
            'DApp Development',
            'Wallet Development',
         ],
      },
      {
         image: Setting,
         title: 'NFT Marketplace Development',
         list: [
            'NFT Token Development Services',
            'White Label NFT Marketplace',
            'NFT Minting Platform Development Company',
            'Metaverse NFT MarketPlace',
            'OpenSea Clone',
            'Solanart Clone',
         ],
      },
      {
         image: Ideabulb,
         title: 'Smart Project Management',
         list: [
            'System-level design and oversight',
            'Quantified risk mitigation',
            'Focus on the most important product features first',
            'Quick development sprints and course corrections',
            'Frequent feedback between product, business, and technical teams',
         ],
      },
      {
         image: Setting,
         title: 'Manufacturing Support',
         list: [
            'Early DFM and sourcing reviews with manufacturers',
            'Assembly and fixturing design/development',
            'CT/FT design/development',
            'Line and factory flow design',
            'Quality inspection design/drawings',
            'Quality system review',
         ],
      },
   ];

   return (
      <>
         <div className="relative flex justify-center items-center overflow-hidden px-8 pt-10 sm:pt-14">
            <div className="text-center">
               <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                  <span className="text-3xl sm:text-4xl tracking-tight text-white">
                     Explore <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                        Our Expertise
                     </span>
                  </span>
                  <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                     Our team of experts provides the necessary technical aid to present outstanding products in the market. Shaping Ideas into reality.
                  </p>
               </div>

               <div className="grid md:grid-cols-2 gap-x-14 mt-10" data-aos="fade-up" data-aos-duration="900">
                  {DataArr?.map((data, index) => (
                     <div className="flex flex-col text-start mt-10 md:mt-14" key={index}>
                        <div className="flex gap-3 ">
                           <img src={data.image} className='float-left w-7' alt={`Expertise ${index}`} />
                           <p className="text-2xl tracking-wide font-medium text-white">
                              {data?.title}
                           </p>
                        </div>
                        {/* <p className="mt-5 text-base leading-7 text-white-light">
                        {data?.desc}
                     </p> */}
                        <ul className='mt-5 pl-8 text-base leading-7 text-white-light list-disc'>
                           {data?.list?.map((item, i) => (
                              <li key={i}>{item}</li>
                           ))}
                        </ul>
                     </div>
                  ))}
               </div>
            </div>
         </div>
         <Platforms />
         <ClientReviews />
      </>
   )
}

export default Expertise;