import React, { createContext, useState } from 'react';

// import MyBricks from '../Assets/Images/Services/SomeOfOurWork/MyBricks.png'
// import Mercore from "../Assets/Images/Services/SomeOfOurWork/Mercore.png"
// import Darabase from "../Assets/Images/Services/SomeOfOurWork/Darabase.png"
// import Serendipity from "../Assets/Images/Services/SomeOfOurWork/Serendipity.png"

import HawkeyeMain from '../Assets/Images/SelectedWork/Hawkeye/3.jpeg';
import TackGPSMain from '../Assets/Images/SelectedWork/TackGPS/1.jpeg';
import eTagMain from '../Assets/Images/SelectedWork/eTag/eTag.jpeg';
import BlazarMain from '../Assets/Images/SelectedWork/Blazar/blazar.jpeg';
import CoreadyMain from '../Assets/Images/SelectedWork/Coready/coready.jpeg';
import DbilMain from '../Assets/Images/SelectedWork/DBIL/dbil.jpeg';
import DotMain from '../Assets/Images/SelectedWork/Dot/dot.jpeg';
import HilyteMain from '../Assets/Images/SelectedWork/Hilyte/1.jpeg';
import SpritswapMain from '../Assets/Images/SelectedWork/SpiritSwap/1.png';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {

    const [isContactDrawerOpen, setIsContactDrawerOpen] = useState(false);

    // const OurWorkArr = [
    //     {
    //         id: 'MyBricks',
    //         name: "My Bricks",
    //         desc: "With the future MyBricks platform you’ll be able to use your BRICKS tokens to purchase fractional ownership of property portfolios. Properties will generate monthly yield that will be distributed to all owners of that particular portfolio.",
    //         image: MyBricks,
    //     },
    //     {
    //         id: 'Mercore',
    //         name: "Mercore",
    //         desc: "The Customer platform supports the onboarding and lifecycle management of corporates and financiers. The Ops panel provides a platform for Mercore analysts to view customer information and manage them through identification and due diligence processes.",
    //         image: Mercore,
    //     },
    //     {
    //         id: 'Darabase',
    //         name: "Darabase",
    //         desc: "The darabase.io NFT marketplace enables property owners to register their properties onto the Darabase ecosystem and profit from AR advertisement placement. They are also free to trade their property's digital rights in the form of NFTs, with other darabase.io users and establish a steady royalty based income.",
    //         image: Darabase,
    //     },
    //     {
    //         name: "Serendipity",
    //         desc: "A charity platform founded on the core belief that donating to charity should be a smooth and seamless experience came to Tech Alchemy to build out its technology. The mission was to facilitate fewer drop-offs in the donation process which would then translate to more money where it’s needed.",
    //         image: Serendipity,
    //     },
    // ];

    const OurWorkArr = [
        {
            id: 'Hawkeye',
            name: "Hawkeye",
            redirectTo: '/selected-work/hawkeye',
            desc: "Hawkeye as it’s in the name it says it all, a sleek design and provides safety as the new X1 Pro comes with a lot of new features. The application now allows users to track the car on the map and access the video from any corner around the city. Meet the new experience, powerful image sensor, new menu UI design, new OLED screen, and the list goes on. The Hawkeye is a new generation beast for those who love technology.",
            image: HawkeyeMain,
        },
        {
            id: 'TackGPS',
            name: "Tack GPS",
            redirectTo: '/selected-work/tack-gps',
            desc: "Tack GPS Tracker, the ultra long-lasting location tracker, locate what you love and keep what matters to you closer all the time. Tack is a groundbreaking standalone location tracker that runs for 1 month on a single charge in a super-compact package! There is a wide range of spectacular specs of this device which is remarkable and commendable.",
            image: TackGPSMain,
        },
        {
            id: 'eTag',
            name: "eTag",
            redirectTo: '/selected-work/etag',
            desc: "This unique product allows passengers to check-in and tag bags securely removed from the airport for the primary time, completing the digital transformation of the check-in process. Eliminate the negative passenger experience of them checking-in online from their smartphone or tablet, receiving digital boarding passes, only to be frustrated by having to still join long queues at the airport to test their bags in.",
            image: eTagMain,
        },
        {
            id: 'Blazar',
            name: "Blazar",
            redirectTo: '/selected-work/blazar',
            desc: "Phone to Phone charge or trader data at the speed of 480 Mbps, not only the phone but the user can connect it with the fitness bands, earbuds, smartwatch, wireless headphones, and any other device that needs to charge. It is a product design to save the user in the time of battery crisis, a very easy to use and convenient. It saves a lot of time as the time is essential at this fast pace life we all lead, saving time is a top priority of everyone, and blazar help people make it happen with the help of technology.",
            image: BlazarMain,
        },
        {
            id: 'Dot',
            name: "Dot",
            redirectTo: '/selected-work/dot',
            desc: "The IoT is the future, bringing convenience and ease in the life which runs at a good pace and in hurry, we often forget to switch off the appliances and it stricks us when we are on our way to the destination but don’t worry the DOT is here to rescue, DOT is a smart home invention a switch that can be controlled through the mobile application a total user-friendly device.",
            image: DotMain,
        },
        {
            id: 'Hilyte',
            name: "Hilyte",
            redirectTo: '/selected-work/hilyte',
            desc: "HiLyte is a Swiss-based startup that aims at providing cheap clean energy for people who still use kerosene for lighting. The hiLyte solutions produce enough power to both charge phone and give light to a room for one night. While the R&D team is working from Neuchâtel, the Tanzanian branch was created to produce and distribute locally. This enables the team to build a product that fits completely with the end-users.",
            image: HilyteMain,
        },
        {
            id: 'DBIL',
            name: "DBIL",
            redirectTo: '/selected-work/Dbil',
            desc: "Direct Bazaar international Limited, is a India based direct selling company that commenced with solid principles and enduring vision and goal. They believe every people has tremendous potential and believing in unleashing their potential by empowering them with a stream of high income and enhancing their well-being through quality products and services.",
            image: DbilMain,
        },
        {
            id: 'Coready',
            name: "Coready",
            redirectTo: '/selected-work/coready',
            desc: "Saving the world with Coready! We were facing the epidemic and we all were in this together and to break the chain of the corona infection by making you, your family, friends, and in the end everybody safer, the Coready is specially crafted to enhance the capabilities of social distancing and contact tracking by using the latest cutting edge tech.",
            image: CoreadyMain,
        },
        {
            id: 'SpiritSwap',
            name: "Spirit Swap",
            redirectTo: '/selected-work/spirit-swap',
            desc: "SpiritSwap is a decentralized exchange (DEX) on the Fantom Opera Chain. SpiritSwap's design is based on the Uniswap constant- product automated market maker (AMM).In an AMM, liquidity providers simply deposit a pair of tokens and an algorithm automatically makes markets for the token pair.Traders can easily swap between tokens in the AMM and get guaranteed rates for the swaps.Each swap on SpiritSwap incurs a fee, which gets distributed to liquidity providers as their payment for work.",
            image: SpritswapMain,
        },
    ];

    return (
        <UserContext.Provider value={{ isContactDrawerOpen, setIsContactDrawerOpen, OurWorkArr }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider