import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import DotMain from '../../../Assets/Images/SelectedWork/Dot/dot.jpeg';
import Dot1 from '../../../Assets/Images/SelectedWork/Dot/1.jpeg';
import Dot2 from '../../../Assets/Images/SelectedWork/Dot/2.jpeg';
import Dot3 from '../../../Assets/Images/SelectedWork/Dot/3.jpeg';
import Dot4 from '../../../Assets/Images/SelectedWork/Dot/4.jpeg';

const Dot = () => {

   const caseStudyData = {
      title: 'Dot',
      content: 'The IoT is the future, bringing convenience and ease in the life which runs at a good pace and in hurry, we often forget to switch off the appliances and it stricks us when we are on our way to the destination but don’t worry the DOT is here to rescue, DOT is a smart home invention a switch that can be controlled through the mobile application a total user-friendly device. The device eliminates this risk factor plus facilitates the user as it enables the user to control the power supply through the application as it can be switch on and off plus the great thing is we can set the timer to on and off the selected switch whether it is microwave oven or table lamp you can power it on and off according to your convince and preference also at what time you need it on can be also set by you. ',
      galleryImages: [
         { url: Dot1 },
         { url: Dot2 },
         { url: Dot3 },
         { url: Dot4 },
      ],
      workDetails: 'Our team sat together while designing the concept and evaluating work and making work workflow, the senior application developer, firmware engineer and hardware engineer had all figured out, we had a timeline of 6 months of completing and prototyping the product to move further with the production stage but with the agile work we entered into the prototyping phase quickly within 3 months and there was no much to do as we had 3 prototypes by the end of the month with gave positive results and were sent to the production process. Putting hardware, firmware, and application development expertise combine together we gave birth to the DOT',
      outCome: 'It is ready to be launched in the market by the beginning of 2021 as the sales will be on the E-commerce platform, and for further details and insight please visit www.vegg.co.in',
      year: '2020',
      client: 'Dot',
      services: 'Rapid Experiential Prototyping, Firmware Support, Mobile application Development',
      keyExpertise: 'Firmware & Embedded Development, Mechanical Engineering, Mobile application Development, Manufacturing Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${DotMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Dot;
