import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import Discovery_IMG from "../../../../Assets/Images/Services/BannerImg/discoveryBanner.svg";
import discoveryImg1 from "../../../../Assets/Images/Services/ServiceDetails/Discovery/discoveryImg1.svg"
import discoveryImg2 from "../../../../Assets/Images/Services/ServiceDetails/Discovery/discoveryImg2.svg"
import discoveryImg3 from "../../../../Assets/Images/Services/ServiceDetails/Discovery/discoveryImg3.svg"
import discoveryImg4 from "../../../../Assets/Images/Services/ServiceDetails/Discovery/discoveryImg4.svg"

const Discovery = () => {

    const BannerDescData = [
        {
            text: "Our discovery services serve as the foundation for project success, enabling you to grasp your objectives, pinpoint requirements, and establish a concise roadmap for seamless execution.",
        },
        {
            text: "Our seasoned team collaborates closely with you to explore potential opportunities, analyze obstacles, and align strategies, guaranteeing that your project is primed for success from its inception.",
        }
    ];

    const ServiceDetailsArr = [
        {
            title: "Uncover the path to success",
            desc: "Let's work together to define clear project goals and objectives aligned with your business vision. We'll identify key outcomes and success criteria, ensuring mutual understanding and alignment to deliver results that drive your business forward.",
            image: discoveryImg1,
        },
        {
            title: "Feasibility study",
            desc: "Before committing any resources to development, we conduct comprehensive feasibility studies, excluding legal aspects. Our evaluations encompass available technologies and platforms to determine the most suitable options for your project. Additionally, we assess the technical feasibility of implementing desired features and functionalities within the identified technology landscape.",
            image: discoveryImg2,
        },
        {
            title: "Business analysis",
            desc: "Benefit from our team's extensive domain knowledge spanning various sectors, industries, and technology stacks. Through a meticulous analysis of your objectives, technology needs, and challenges, we pinpoint areas ripe for improvement and innovation. Tap into our expertise to uncover opportunities and precisely define your project scope.",
            image: discoveryImg3,
        },
        {
            title: "Risk analysis",
            desc: "We proactively identify potential risks and challenges linked to selected technologies and propose mitigation strategies to minimize their impact. Additionally, we offer to conduct a cost-benefit analysis, assessing the investment needed against potential returns associated with the chosen technology stack. This ensures informed decision-making and optimized resource allocation for your project.",
            image: discoveryImg4,
        },
    ];

    return (
        <>
            <ServiceBanner
                title="Discovery"
                descData={BannerDescData}
                bannerImage={Discovery_IMG}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <WorkWithUs mainTitle="Work with us to scope the" title="viability of your project" />
        </>
    )
}

export default Discovery;