import React, { useContext } from "react";
import GetStartedBannerImg from '../../../Assets/Images/jpg/Homepage.jpeg';
import { UserContext } from "../../../Context/UserContext";
import Button from "../../../Components/Button/Button";

const GetStartedBanner = () => {

    const { setIsContactDrawerOpen } = useContext(UserContext);

    return (
        <section className="relative overflow-hidden py-32 mt-28">
            <img
                className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2 shadow-inner shadow-slate-dark"
                src={GetStartedBannerImg}
                alt="GetStartedBanner"
                width="2347"
                height="1244"
            />
            <div className="absolute inset-0 opacity-60 bg-black"></div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
                <div className="mx-auto max-w-lg text-center aos-init aos-animate" data-aos="fade-up">
                    <p className="text-3xl sm:text-4xl tracking-tight text-white">
                        Get started Today
                    </p>
                    <p className="mt-4 text-lg tracking-tight text-white">
                    Uncover the mysteries of AI/ML. Let curiosity lead you as you venture into new realms and broaden your understanding.
                    </p>
                    <Button
                        text="Start Now"
                        btnClass="mt-8"
                        btnType="white"
                        onClick={() => setIsContactDrawerOpen(true)}
                    />
                </div>
            </div>
        </section>
    )
}

export default GetStartedBanner;