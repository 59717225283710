import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const ServiceDetails = ({ detailsArr }) => {

    const isMobile = window.innerWidth < 640;

    return (
        <div className="flex flex-col items-center justify-center mx-auto max-w-7xl px-8 py-12 md:py-20">

            {
                isMobile ? (
                    <Swiper
                        className="w-full ServiceDetails"
                        slidesPerView={1}
                        spaceBetween={60}
                        pagination={{ clickable: true }}
                        loop={true}
                        modules={[Pagination]}
                        style={{
                            paddingBottom: "60px",
                        }}
                        data-aos="fade-up" data-aos-duration="900"
                    >
                        {
                            detailsArr?.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10">
                                        <div className="col-span-1">
                                            <img
                                                src={data?.image}
                                                alt="detailImg"
                                                className="rounded md:w-full h-auto"
                                            />
                                        </div>
                                        <div className="col-span-2 flex flex-col gap-5 justify-center">
                                            <p className="text-3xl tracking-wide font-semibold text-purple">
                                                {data?.title}
                                            </p>
                                            <p className="font-medium text-white-light">
                                                {data?.desc}
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                ) : (
                    // <div className="flex flex-col gap-24">
                    //     {
                    //         detailsArr?.map((data, index) => (
                    //             <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-20" key={index} data-aos="fade-up" data-aos-duration="900">
                    //                 <div className={`col-span-2 flex flex-col gap-5 justify-center md:order-${index % 2 === 0 ? 'first' : 'last'}`}>
                    //                     <p className="text-3xl tracking-wide font-semibold text-purple">
                    //                         {data?.title}
                    //                     </p>
                    //                     <p className="font-medium text-white-light">
                    //                         {data?.desc}
                    //                     </p>
                    //                 </div>
                    //                 <div className={`col-span-1 md:order-${index % 2 === 0 ? 'last' : 'first'}`}>
                    //                     <img
                    //                         src={data?.image}
                    //                         alt="detailImg"
                    //                         className="rounded  md:w-full h-auto"
                    //                     />
                    //                 </div>
                    //             </div>
                    //         ))}
                    // </div>
                    <div className="flex flex-col gap-24">
                        {
                            detailsArr?.map((data, index) => (
                                <div
                                    className={`flex ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'} gap-10 md:gap-20`}
                                    key={index}
                                    data-aos="fade-up" data-aos-duration="900"
                                >
                                    <div className="basis-1/3">
                                        <img
                                            src={data?.image}
                                            alt="detailImg"
                                            className="rounded  md:w-full h-auto"
                                        />
                                    </div>
                                    <div className="basis-2/3 flex flex-col gap-5 justify-center">
                                        <p className="text-3xl tracking-wide font-semibold text-purple">
                                            {data?.title}
                                        </p>
                                        <p className="font-medium text-white-light">
                                            {data?.desc}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }

        </div>
    );
}

export default ServiceDetails;