import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";

const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <div className="z-0">
            <section className="flex h-[60vh] flex-col items-center justify-center gap-7 ">
                <div className="flex items-center justify-center gap-4 text-white">
                    <p className="text-2xl font-semibold">404</p>
                    <div className="text-2xl font-extralight">|</div>
                    <p className="text-base">Page not found</p>
                </div>
                <Button
                    text="Go Home"
                    btnClass="px-5 py-3 rounded"
                    onClick={() => navigate("/")}
                />
            </section>
        </div>
    )
}

export default PageNotFound;