import React, { useEffect, useRef, useState } from 'react';
// import logo from '../../Assets/Images/png/Asset 2.png';
// import logo from '../../Assets/Images/png/Asset 3.png';caliente_logo_b.png
import logo from '../../Assets/Images/png/caliente_logo_w.png';

// import logo from '../../Assets/Images/png/caliente_logo.png';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiChevronDown, mdiChevronUp, mdiClose, mdiMenu } from '@mdi/js';
import { Link, NavLink, useLocation } from 'react-router-dom';

const serviceMenuItems = [
    {
        title: "Blockchain",
        links: [
            { text: "Fintech & Defi", to: "/services/fintech-defi" },
            { text: "Data Immutability", to: "/services/data-immutability" },
            { text: "NFTs", to: "/services/nfts" },
            { text: "Smart Tokens", to: "/services/smart-tokens" },
            { text: "Metaverse", to: "/services/metaverse" },
            { text: "Blockchain", to: "/services/blockchain" }
        ]
    },
    {
        title: "Product development",
        links: [
            { text: "Discovery", to: "/services/discovery" },
            { text: "Prototyping", to: "/services/prototyping" },
            { text: "Mobile Development", to: "/services/mobile-development" },
            { text: "Web Development", to: "/services/web-development" }
        ]
    },
    {
        title: "Software solutions",
        links: [
            { text: "AI & Automation", to: "/services/ai-automation" },
            { text: "Cybersecurity", to: "/services/cybersecurity" },
            { text: "Data Analytics", to: "/services/data-analytics" },
            { text: "Digital Transformation", to: "/services/digital-transformation" },
        ]
    },
    {
        title: "Apps",
        links: [
            { text: "Gaming", to: "/services/gaming" },
            { text: "Mobile App", to: "/services/mobile-app" }
        ]
    }
];

const Header = () => {

    const headerRef = useRef(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [isServiceMenuOpen, setIsServiceMenuOpen] = useState(false);
    const [isServiceMenuHover, setIsServiceMenuHover] = useState(false);
    const path = useLocation()?.pathname;

    const handleMouseEnter = () => {
        if (window.innerWidth > 768) {
            setIsServiceMenuHover(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isServiceMenuOpen && window.innerWidth > 768) {
            setIsServiceMenuHover(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (headerRef?.current && !headerRef?.current?.contains(event?.target) && window.innerWidth > 768) {
                setIsServiceMenuOpen(false);
                setIsServiceMenuHover(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showDrawer || isServiceMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showDrawer, isServiceMenuOpen]);

    // useEffect(() => {
    //     const handleOverflow = () => {
    //         if (showDrawer) {
    //             document.documentElement.style.overflow = 'hidden';
    //         } else {
    //             document.documentElement.style.overflow = 'auto';
    //         }
    //     };

    //     handleOverflow(); // Call it initially to set overflow state

    //     // Event listener to handle overflow on resize
    //     window.addEventListener('resize', handleOverflow);

    //     return () => {
    //         // Clean up event listener
    //         window.removeEventListener('resize', handleOverflow);

    //         // Reset overflow when component unmounts
    //         document.documentElement.style.overflow = 'auto';
    //     };
    // }, [showDrawer]);

    const closeDrawer = () => {
        setShowDrawer(false);
        setIsServiceMenuOpen(false);
        setIsServiceMenuHover(false);
    }

    const isActive = (itemPath) => {
        return path?.startsWith(itemPath);
    };

    const ServicesMenu = () => {
        return (
            <div
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
                data-aos="fade-down"
                data-aos-duration="300"
                data-aos-delay="200"
            // data-aos-once="true"
            >
                {serviceMenuItems?.map((section, index) => (
                    <div key={index} className="w-full">
                        <p className='text-[22px] font-semibold py-3 tracking-wide text-purple'>{section?.title}</p>
                        <div className='tracking-tight border-t border-slate-dark'></div>
                        <div className="flex flex-col gap-2 mt-5">
                            {section?.links?.map((link, linkIndex) => (
                                <Link
                                    key={linkIndex}
                                    to={link?.to}
                                    onClick={() => { closeDrawer(); isActive('/services') }}
                                    className={`text-lg ${isActive(link?.to) ? 'text-purple font-semibold' : 'text-white hover:text-grey-light'}`}
                                >
                                    {link?.text}
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const Drawer = () => {
        return (
            <div className="fixed flex justify-center items-center z-50 inset-0 bg-white-light/50 opacity-100">
                <div className="w-full sm:max-w-md fixed top-0 left-0 flex flex-col gap-3 inset-0 overflow-auto bg-dark-blue">
                    <div className="flex justify-between items-center sticky top-0 p-4 z-30 bg-dark-blue">
                        <span className="text-2xl font-semibold text-white">Menu</span>
                        <Icon path={mdiClose} size={1} className='cursor-pointer text-white' onClick={closeDrawer} />
                    </div>
                    <div className="p-4 pb-10">
                        {
                            isServiceMenuOpen ?
                                <>
                                    <div
                                        className="flex gap-4 cursor-pointer w-fit text-white"
                                        onClick={() => setIsServiceMenuOpen(false)}
                                    >
                                        <Icon
                                            path={mdiArrowLeft}
                                            size={1}
                                            className='mb-5'
                                        />
                                        <p>Main Menu</p>
                                    </div>
                                    <ServicesMenu />
                                </>
                                :
                                <NavBarItems />
                        }
                    </div>
                </div>
            </div>
        );
    }

    const NavBarItems = () => {

        const navItems = [
            {
                to: '/selected-work',
                name: '/selected-work',
                text: 'Selected work',
                onclick: () => { closeDrawer(); }
            },
            {
                name: '/services',
                text: 'Services',
                isIcon: true,
                onclick: () => { setIsServiceMenuOpen(!isServiceMenuOpen); },
                onMouseEnter: () => { handleMouseEnter(); },
                onMouseLeave: () => { handleMouseLeave(); },
            },
            {
                to: '/expertise',
                name: '/expertise',
                text: 'Expertise',
                onclick: () => { closeDrawer(); }
            },
            {
                to: '/careers',
                name: '/careers',
                text: 'Careers',
                onclick: () => { closeDrawer(); }
            }
        ];

        return (
            <ul className='flex flex-col md:flex-row gap-7 md:gap-5'>
                {navItems?.map((item, index) => (
                    <div
                        key={index}
                        onMouseEnter={item?.onMouseEnter}
                        onMouseLeave={item?.onMouseLeave}
                    >
                        <NavLink
                            to={item?.to}
                            className={`flex items-center text-lg md:text-[17px] md:h-[var(--header-height)]
                                         ${isActive(item?.name) ? 'text-purple font-semibold' : 'text-white md:hover:text-grey-light'}`}
                            onClick={item?.onclick}
                        >
                            {item?.text}
                            {
                                item?.isIcon && (
                                    isServiceMenuOpen || isServiceMenuHover ?
                                        <Icon path={mdiChevronUp} size={1} />
                                        :
                                        <Icon path={mdiChevronDown} size={1} />
                                )
                            }
                        </NavLink>
                    </div>
                ))}
            </ul>
        );
    }

    return (
        <>
            {(isServiceMenuOpen || isServiceMenuHover) &&
                <div className="fixed inset-0 backdrop-blur-[10px] z-20"></div>
            }
            <header
                ref={headerRef}
                className={`sticky top-0 z-20 py-3 h-[var(--header-height)] flex flex-col items-center justify-center
                                ${isServiceMenuOpen || isServiceMenuHover ? 'bg-dark-blue' : 'backdrop-blur-xl'}`}
            >
                <div className="w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                    <div className="flex justify-between items-center relative z-30">
                        <NavLink to="/">
                            <img src={logo} alt="logo" width={180} />
                        </NavLink>
                        <div className="block md:hidden">
                            <button onClick={() => setShowDrawer(true)}>
                                <Icon path={mdiMenu} size={1.5} className='text-white' />
                            </button>
                        </div>
                        <div className="hidden md:block">
                            <NavBarItems />
                        </div>
                    </div>
                    <div
                        className={`absolute hidden md:block left-0 w-full z-20 subMenuDiv bg-dark-blue
                                        ${isServiceMenuOpen || isServiceMenuHover ? 'h-auto py-10 top-[var(--header-height)]' : 'h-0 overflow-hidden'}`
                        }
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                            overflow: 'auto',
                            maxHeight: 'calc(100vh - 80px)'
                        }}
                    >
                        <div className="w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <ServicesMenu />
                        </div>
                    </div>
                </div>
            </header>
            {
                showDrawer &&
                <Drawer />
            }
        </>
    );
};

export default Header;