import { useState } from 'react';

const useForm = (onSubmit) => {
   const [values, setValues] = useState({});
   const [errors, setErrors] = useState({});
   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

   const handleInputChange = (e, name) => {
      const targetName = e?.target?.name || name;
      setValues((prevValues) => ({
         ...prevValues,
         [targetName]: targetName === name ? e?.name : e?.target?.value,
      }));
      setErrors((prevErrors) => ({
         ...prevErrors,
         [targetName]: '',
      }));
   };

   const handleReCaptchaVerify = (token) => {
      if (token) {
         setIsCaptchaVerified(true);
         setErrors({ reCaptcha: '' });
      } else {
         setIsCaptchaVerified(false);
         setErrors({ reCaptcha: 'Please verify reCAPTCHA' });
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      try {
         // Use the provided onSubmit function
         await onSubmit(e);

         // Handle additional logic after successful submission
      } catch (error) {
         console.error('Error handling form submission:', error);
      }
   };

   return {
      values,
      setValues,
      errors,
      setErrors,
      isCaptchaVerified,
      setIsCaptchaVerified,
      handleInputChange,
      handleReCaptchaVerify,
      handleSubmit: (e) => handleSubmit(e),
   };
};

export default useForm;
