import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import FINTECH_IMG from "../../../../Assets/Images/Services/BannerImg/fintech-banner.svg";

import DetailImg01 from "../../../../Assets/Images/Services/ServiceDetails/FintechDefi/unsplash-service01.svg"
import DetailImg02 from "../../../../Assets/Images/Services/ServiceDetails/FintechDefi/unsplash-service02.svg"

import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import Blockchain from '../../../../Assets/Images/Services/TechStack/Blockchain.svg'
import Angular from '../../../../Assets/Images/Services/TechStack/Angular.svg'
import ReactImg from '../../../../Assets/Images/Services/TechStack/React.svg'
import NextJS from '../../../../Assets/Images/Services/TechStack/NextJS.svg'
import Nativescript from '../../../../Assets/Images/Services/TechStack/Nativescript.svg'
import NodeJS from '../../../../Assets/Images/Services/TechStack/NodeJS.svg'
import PHP from '../../../../Assets/Images/Services/TechStack/PHP.svg'
import Typescript from '../../../../Assets/Images/Services/TechStack/Typescript.svg'
import Python from '../../../../Assets/Images/Services/TechStack/Python.svg'

const FintechDefi = () => {

    const techImages = [
        Ethereum,
        Polygon,
        Binance,
        Tron,
        Near,
        QLDB,
        Metamask,
        Hyperledger,
        Solidity,
        Web3JS,
        ERC20,
        Blockchain,
        Angular,
        ReactImg,
        NextJS,
        Nativescript,
        NodeJS,
        PHP,
        Typescript,
        Python,
    ];

    const BannerDescData = [
        {
            text: "Our expertise lies in fintech solutions aimed at bolstering data security, ensuring data immutability, and developing platforms that optimize payment processes, enhance transaction speeds, and minimize costs.",
        },
        {
            text: "With extensive experience spanning financial services and capital markets, we adeptly tackle a range of challenges while spearheading innovation initiatives.",
        }
    ];

    const ServiceDetailsArr = [
        {
            title: "Shape the future of finance",
            desc: "We are tech alchemists, going beyond traditional software engineering. We serve as strategic partners to leading financial institutions, empowering them to tap into blockchain resources, and partnering with fintech firms to enhance their competitive advantage.",
            image: DetailImg01,
        },
        {
            title: "Fintech app development",
            desc: "We offer a full range of fintech and DeFi design and development solutions, encompassing smart contract development, decentralized applications (DApps), tokenization, and beyond. Leveraging our deep-rooted experience in both traditional finance and cutting-edge technologies, we fuse our expertise to create truly groundbreaking solutions.",
            image: DetailImg02,
        },
    ];

    return (
        <>
            <ServiceBanner
                title="FINTECH & DeFi"
                descData={BannerDescData}
                bannerImage={FINTECH_IMG}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to build" title="DeFi solutions" />
        </>
    )
}

export default FintechDefi;