import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SpeedInsights } from '@vercel/speed-insights/react';

// import './App.css';
import './Assets/css/style.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './Pages/Common/Header';
import HomePage from './Pages/HomePage/HomePage';
import Footer from './Pages/Common/Footer';
import ContactDrawer from './Pages/Common/ContactDrawer';
import UserContextProvider from './Context/UserContext';
import SelectedWork from './Pages/SelectedWork/SelectedWork';
import Chatbot from './Pages/Common/Chatbot';
import Expertise from './Pages/Expertise/Expertise';
import Services from './Pages/Services/Services';
import Hawkeye from './Pages/SelectedWork/CaseStudies/Hawkeye';
import TackGps from './Pages/SelectedWork/CaseStudies/TackGps';
import PageNotFound from './Pages/Common/PageNotFound';
import ETag from './Pages/SelectedWork/CaseStudies/eTag';
import Blazar from './Pages/SelectedWork/CaseStudies/Blazar';
import Hilyte from './Pages/SelectedWork/CaseStudies/Hilyte';
import Dot from './Pages/SelectedWork/CaseStudies/Dot';
import Dbil from './Pages/SelectedWork/CaseStudies/Dbil';
import Coready from './Pages/SelectedWork/CaseStudies/Coready';
import Spiritswap from './Pages/SelectedWork/CaseStudies/Spiritswap';
import Careers from './Pages/Careers/Careers';
import FintechDefi from './Pages/Services/ServiceMenu/Blockchain/FintechDefi';
import Discovery from './Pages/Services/ServiceMenu/ProductDevelopment/Discovery';
import DataImmutability from './Pages/Services/ServiceMenu/Blockchain/DataImmutability';
import NFTs from './Pages/Services/ServiceMenu/Blockchain/NFTs';
import SmartTokens from './Pages/Services/ServiceMenu/Blockchain/SmartTokens';
import Metaverse from './Pages/Services/ServiceMenu/Blockchain/Metaverse';
import AiAndAutomation from './Pages/Services/ServiceMenu/SoftwareSolutions/AiAndAutomation';
import Cybersecurity from './Pages/Services/ServiceMenu/SoftwareSolutions/Cybersecurity';
import DataAnalytics from './Pages/Services/ServiceMenu/SoftwareSolutions/DataAnalytics';
import DigitalTransformation from './Pages/Services/ServiceMenu/SoftwareSolutions/DigitalTransformation';
import Prototyping from './Pages/Services/ServiceMenu/ProductDevelopment/Prototyping';
import MobileDevelopment from './Pages/Services/ServiceMenu/ProductDevelopment/MobileDevelopment';
import WebDevelopment from './Pages/Services/ServiceMenu/ProductDevelopment/WebDevelopment';
import Gaming from './Pages/Services/ServiceMenu/Apps/Gaming';
import MobileApp from './Pages/Services/ServiceMenu/Apps/MobileApp';
import Blockchain from './Pages/Services/ServiceMenu/Blockchain/Blockchain';
import FantomDao from './Pages/SelectedWork/CaseStudies/FantomDao';
import Nafter from './Pages/SelectedWork/CaseStudies/Nafter';
import Degenape from './Pages/SelectedWork/CaseStudies/Degenape';
import SphynxLabs from './Pages/SelectedWork/CaseStudies/SphynxLabs';
import Multisender from './Pages/SelectedWork/CaseStudies/Multisender';
import Odda from './Pages/SelectedWork/CaseStudies/Odda';
import Medisend from './Pages/SelectedWork/CaseStudies/Medisend';
// import ReCAPTCHA from 'react-google-recaptcha';

const App = () => {

  // const recaptchaRef = useRef();

  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
    // onSubmitWithReCAPTCHA();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const onSubmitWithReCAPTCHA = async () => {
  //   const token = await recaptchaRef.current.executeAsync();
  //   console.log('token', token)
  //   // apply to form data
  // }

  // useEffect(() => {
  //   const loadReCaptcha = async () => {
  //     await new Promise(resolve => {
  //       window.grecaptcha.ready(resolve);
  //     });

  //     const token = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' });
  //     // You can use the token for your form submission or any other purpose.
  //     console.log(token);
  //   };

  //   loadReCaptcha();
  // }, []);

  const getTitle = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Blockchain - IoT - AI development | Caliente iTech';

      case '/selected-work':
        return 'Selected Work - Caliente iTech';
      case '/selected-work/hawkeye':
        return 'Hawkeye - Selected Work - Caliente iTech';
      case '/selected-work/tack-gps':
        return 'Tack GPS - Selected Work - Caliente iTech';
      case '/selected-work/etag':
        return 'eTag - Selected Work - Caliente iTech';
      case '/selected-work/blazar':
        return 'Blazar - Selected Work - Caliente iTech';
      case '/selected-work/hilyte':
        return 'Hilyte - Selected Work - Caliente iTech';
      case '/selected-work/dot':
        return 'Dot - Selected Work - Caliente iTech';
      case '/selected-work/Dbil':
        return 'DBIL - Selected Work - Caliente iTech';
      case '/selected-work/coready':
        return 'Coready - Selected Work - Caliente iTech';
      case '/selected-work/spirit-swap':
        return 'Spirit Swap - Selected Work - Caliente iTech';
      case '/selected-work/fantom-dao':
        return 'Fantom Dao - Selected Work - Caliente iTech';
      case '/selected-work/nafter':
        return 'Nafter - Selected Work - Caliente iTech';
      case '/selected-work/degenape':
        return 'Degenape - Selected Work - Caliente iTech';
      case '/selected-work/sphynx-labs':
        return 'Sphynx Labs - Selected Work - Caliente iTech';
      case '/selected-work/multisender':
        return 'Multisender - Selected Work - Caliente iTech';
      case '/selected-work/odda':
        return 'ODDA - Selected Work - Caliente iTech';
      case '/selected-work/medisend':
        return 'Medisend - Selected Work - Caliente iTech';

      case '/services':
        return 'Services - Caliente iTech';
      case '/services/fintech-defi':
        return 'Fintech DeFi - Services - Caliente iTech';
      case '/services/data-immutability':
        return 'Data Immutability - Services - Caliente iTech';
      case '/services/nfts':
        return 'NFTs - Services - Caliente iTech';
      case '/services/smart-tokens':
        return 'Smart Tokens - Services - Caliente iTech';
      case '/services/metaverse':
        return 'Metaverse - Services - Caliente iTech';
      case '/services/blockchain':
        return 'Blockchain - Services - Caliente iTech';
      case '/services/discovery':
        return 'Discovery - Services - Caliente iTech';
      case '/services/prototyping':
        return 'Prototyping - Services - Caliente iTech';
      case '/services/mobile-development':
        return 'Mobile Development - Services - Caliente iTech';
      case '/services/web-development':
        return 'Web Development - Services - Caliente iTech';
      case '/services/ai-automation':
        return 'AI and Automation - Services - Caliente iTech';
      case '/services/cybersecurity':
        return 'Cybersecurity - Services - Caliente iTech';
      case '/services/data-analytics':
        return 'Data Analytics - Services - Caliente iTech';
      case '/services/digital-transformation':
        return 'Digital Transformation - Services - Caliente iTech';
      case '/services/gaming':
        return 'Gaming - Services - Caliente iTech';
      case '/services/mobile-app':
        return 'Mobile Applications - Services - Caliente iTech';

      case '/expertise':
        return 'Expertise - Caliente iTech';
      case '/careers':
        return 'Careers - Caliente iTech';
      default:
        return 'Caliente iTech';
    }
  }

  return (
    <>
      <UserContextProvider>
        <Helmet>
          <title>{getTitle(pathname)}</title>
        </Helmet>
        <Header />
        <main>
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            // onChange={handleRecaptchaVerify}
            size='invisible'
          /> */}
          <ContactDrawer />
          <Chatbot />
          <Routes>
            <Route exact path="/" element={<HomePage />} />

            <Route exact path="/selected-work" element={<SelectedWork />} />
            <Route exact path="/selected-work/hawkeye" element={<Hawkeye />} />
            <Route exact path="/selected-work/tack-gps" element={<TackGps />} />
            <Route exact path="/selected-work/etag" element={<ETag />} />
            <Route exact path="/selected-work/blazar" element={<Blazar />} />
            <Route exact path="/selected-work/hilyte" element={<Hilyte />} />
            <Route exact path="/selected-work/dot" element={<Dot />} />
            <Route exact path="/selected-work/Dbil" element={<Dbil />} />
            <Route exact path="/selected-work/coready" element={<Coready />} />
            <Route exact path="/selected-work/spirit-swap" element={<Spiritswap />} />
            <Route exact path="/selected-work/fantom-dao" element={<FantomDao />} />
            <Route exact path="/selected-work/nafter" element={<Nafter />} />
            <Route exact path="/selected-work/degenape" element={<Degenape />} />
            <Route exact path="/selected-work/sphynx-labs" element={<SphynxLabs />} />
            <Route exact path="/selected-work/multisender" element={<Multisender />} />
            <Route exact path="/selected-work/odda" element={<Odda />} />
            <Route exact path="/selected-work/medisend" element={<Medisend />} />

            <Route exact path="/services/fintech-defi" element={<FintechDefi />} />
            <Route exact path="/services/data-immutability" element={<DataImmutability />} />
            <Route exact path="/services/nfts" element={<NFTs />} />
            <Route exact path="/services/smart-tokens" element={<SmartTokens />} />
            <Route exact path="/services/metaverse" element={<Metaverse />} />
            <Route exact path="/services/blockchain" element={<Blockchain />} />

            <Route exact path="/services/discovery" element={<Discovery />} />
            <Route exact path="/services/prototyping" element={<Prototyping />} />
            <Route exact path="/services/mobile-development" element={<MobileDevelopment />} />
            <Route exact path="/services/web-development" element={<WebDevelopment />} />

            <Route exact path="/services/ai-automation" element={<AiAndAutomation />} />
            <Route exact path="/services/cybersecurity" element={<Cybersecurity />} />
            <Route exact path="/services/data-analytics" element={<DataAnalytics />} />
            <Route exact path="/services/digital-transformation" element={<DigitalTransformation />} />

            <Route exact path="/services/gaming" element={<Gaming />} />
            <Route exact path="/services/mobile-app" element={<MobileApp />} />

            <Route exact path="/expertise" element={<Expertise />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
        <Footer />
      </UserContextProvider>
      <SpeedInsights />
    </>
  );
}

export default App;
