import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';

import eTagMain from '../../../Assets/Images/SelectedWork/eTag/eTag.jpeg';
import eTag1 from '../../../Assets/Images/SelectedWork/eTag/1.jpeg';
import eTag2 from '../../../Assets/Images/SelectedWork/eTag/2.jpeg';

const ETag = () => {
   const caseStudyData = {
      title: 'eTag',
      content: 'This unique product allows passengers to check-in and tag bags securely removed from the airport for the primary time, completing the digital transformation of the check-in process. Eliminate the negative passenger experience of them checking-in online from their smartphone or tablet, receiving digital boarding passes, only to be frustrated by having to still join long queues at the airport to test their bags in. Using eTag, the web check-in process is extended via the airline’s App to incorporate bags. Travelers can now reach the airport with their boarding card along with their bags tagged with the smart eTag, makes it easy to departures in a fraction of seconds.',
      galleryImages: [
         { url: eTag1 },
         { url: eTag2 },

      ],
      workDetails: 'Crafting this innovative product was a fantastic experience for our team as they enjoy working, it had involved a wide range of work, from developing the hardware, firmware, and the application we also provided prototyping to the eTag. with our expertise in embedded software and agile hardware designing, we managed to cover up the work before the expected time.',
      outCome: `eTag is that the only two-sided electronic bag tag, allowing it to behave in same way as the old school paper tags, sort of a paper tag, eTag securely attaches to the handle of your case, it's not permanently attached to the case and it is easily removable and can swapped to a different case.By attaching to the handle eTag doesn't cause any damage to your case, saving time of the user together with tones of paper per annum they need made a positive change, and no wonder they're going to keep the consistency up.`,
      year: '2020',
      client: 'eTag',
      services: 'Firmware Support, Embedded Development, Rapid Testing',
      keyExpertise: 'Firmware & Embedded Development, Mobile application Development, Manufacturing Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${eTagMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default ETag;
