import React from "react";

const Input = ({ id, name, label, type, required, error, value, onChange, placeholder }) => {

    return (
        <div className="flex flex-col w-full">
            {
                label &&
                <label htmlFor={id} className="mb-2 text-base text-white-light">
                    {label}
                </label>
            }
            <input
                id={id}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                className={`py-1 w-full focus:outline-none bg-transparent border-b text-lg md:text-base border-zinc text-white`}
                required={required}
                placeholder={placeholder}
            />
            {
                error &&
                <p className="text-red-600">{error}</p>
            }
        </div>
    )
}

export default Input;