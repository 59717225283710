import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Icon from "@mdi/react";
import { mdiUnfoldMoreHorizontal } from "@mdi/js";

const Dropdown = ({ value, Options, id, name,onChange }) => {

    return (
        <Listbox value={value} name={name} onChange={onChange}>
            <div className="relative mt-1">
                <Listbox.Button
                    id={id}
                    className="relative text-left w-full py-2 focus:outline-none bg-transparent border-b border-zinc text-white"
                >
                    <span className={`block truncate text-lg md:text-base ${value ? 'text-white' : 'text-grey-light'}`}>
                        {value || 'Select your budget'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <Icon path={mdiUnfoldMoreHorizontal} size={1} />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options
                        className="absolute flex flex-col gap-1 mt-1 p-2 max-h-60 z-10 w-full overflow-auto rounded text-base
                                    sm:text-sm shadow-lg ring-1 ring-black/5 focus:outline-none bg-grey-dark-01"
                    >
                        {Options?.map((data, index) => {
                            return (
                                <Listbox.Option
                                    key={index}
                                    className={`relative p-2 rounded cursor-pointer text-white hover:bg-white hover:bg-opacity-10 
                                                ${data?.value === value ? 'bg-white bg-opacity-10' : 'bg-transparent'}`}
                                    value={data}
                                >
                                    {({ selected }) => (
                                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                            {data?.name}
                                        </span>
                                    )}
                                </Listbox.Option>
                            )
                        })}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default Dropdown;