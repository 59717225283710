import React from "react";
import logo from '../../Assets/Images/png/caliente_logo_w.png';
// import logo from '../../Assets/Images/png/caliente_logo.png';
import Icon from '@mdi/react';
import { mdiFacebook, mdiGooglePlus, mdiInstagram, mdiLinkedin } from '@mdi/js';
import { Link, NavLink } from "react-router-dom";

const Footer = () => {

    const phoneNumber = "+91.94294.79463";
    const socialMediaLinks = [
        { icon: mdiFacebook, url: 'https://www.facebook.com/CalienteiTech/' },
        { icon: mdiInstagram, url: 'https://www.instagram.com/calienteitech/' },
        { icon: mdiLinkedin, url: 'https://www.linkedin.com/company/calienteitech' },
        { icon: mdiGooglePlus, url: 'https://plus.google.com/+calienteitech' }
    ];

    return (
        <footer className="flex justify-between pt-14 pb-20 px-8 mx-auto max-w-7xl">
            <div className="hidden sm:block">
                <NavLink to="/">
                    <img src={logo} alt="logo" width={250} />
                </NavLink>
            </div>
            <div className="flex flex-col w-full sm:w-auto sm:flex-row gap-10">
                <div className="text-grey-light">
                    <p>235 Crystal Plaza.</p>
                    <p>Nr Devi Darshan</p>
                    <p>Punagam, Surat</p>
                    <p>GJ, IN 395010</p>
                </div>
                <div>
                    <Link className="text-white-light" to={`tel:${phoneNumber}`}>
                        {phoneNumber}
                    </Link>
                    <div className="flex gap-5 mt-5 text-grey-light">
                        {socialMediaLinks?.map((link, index) => (
                            <Link key={index} to={link?.url} target="_blank" rel="noreferrer">
                                <Icon path={link?.icon} size={1} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;