import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import DegenapeMain from '../../../Assets/Images/SelectedWork/Degenape/DegenapeTransparent.png';
import Degenape1 from '../../../Assets/Images/SelectedWork/Degenape/1.png';
import Degenape2 from '../../../Assets/Images/SelectedWork/Degenape/2.png';

const Degenape = () => {

   const caseStudyData = {
      title: 'Degenerate Ape Academy',
      content: `Deep in the heart of Dingus Forest echoes the sleepless cries of a troop of 10,000 apes. These aren't just regular apes, however. These are degenerate apes.`,
      galleryImages: [
         { url: Degenape1 },
         { url: Degenape2 },
      ],
      workDetails: 'In the heart of Dingus Forest lies the Degenerate Ape Academy, a project where our team poured their creativity and technical expertise. With meticulous attention to detail, we brought to life the vibrant world of 10,000 degenerate apes, each with its unique characteristics and quirks. From coding intricate smart contracts to designing immersive user experiences, our work has laid the foundation for a thriving community where these digital apes roam freely. We take pride in our contribution to the Degenerate Ape Academy and look forward to witnessing its continued growth and success in the evolving landscape of blockchain-based projects.',
      outCome: 'We were honored to collaborate on the Degenerate Ape Academy project and are delighted to have contributed to its success. The team behind the project was impressed by our dedication and the quality of work we delivered. We take pride in being part of their journey and are thrilled to have played a role in bringing their vision to life. We extend our best wishes to the Degenerate Ape Academy team for their continued success, and we eagerly anticipate the opportunity to work together again in the future.',
      year: '2021',
      client: 'Degenerate Ape Academy',
      services: 'NFT, Blockchain, React.js, Next.js, Smart contract Development',
      keyExpertise: 'NFT, Smart Contract Development, React.js, Next,js, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${DegenapeMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Degenape;
