import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import Mobile_IMG from "../../../../Assets/Images/Services/BannerImg/mobile-app-banner.png";

import DetailImg01 from '../../../../Assets/Images/Services/ServiceDetails/MobileApp/unsplash-service01.svg'
import DetailImg02 from '../../../../Assets/Images/Services/ServiceDetails/MobileApp/unsplash-service2.svg'
import DetailImg03 from '../../../../Assets/Images/Services/ServiceDetails/MobileApp/unsplash-service3.svg'
import DetailImg04 from '../../../../Assets/Images/Services/ServiceDetails/MobileApp/unsplash-service4.svg'
import DetailImg05 from '../../../../Assets/Images/Services/ServiceDetails/MobileApp/unsplash-service5.svg'
import DetailImg06 from '../../../../Assets/Images/Services/ServiceDetails/MobileApp/unsplash-service6.svg'

import btnSwift from '../../../../Assets/Images/Services/TechStack/btnSwift.svg'
import Flutter from '../../../../Assets/Images/Services/TechStack/Flutter.svg'
import Kotlin from '../../../../Assets/Images/Services/TechStack/Kotlin.svg'
import AR from '../../../../Assets/Images/Services/TechStack/AR.svg'

const MobileApp = () => {

  const techImages = [
    btnSwift,
    Flutter,
    Kotlin,
    AR,
  ];

  const BannerDescData = [
    {
      text: "Our expertise lies in the development of compelling and feature-packed mobile applications, aiming to boost engagement, elevate user experiences, and propel your business to new heights.",
    },
    {
      text: "Whether you're a startup, an enterprise, or an organization seeking to create a native, hybrid, or web app, our customized mobile app services are crafted to align with your distinctive requirements and goals.",
    }
  ];

  const ServiceDetailsArr = [
    {
      title: "Exceptional mobile app solutions to drive growth and enhance user engagement",
      desc: "Crafting bespoke mobile applications that resonate with your distinct business goals and requirements is our forte. Our seasoned team of developers leverages cutting-edge technologies and frameworks to produce high-performance, feature-rich, and user-friendly mobile apps, ensuring an outstanding user experience.",
      image: DetailImg01,
    },
    {
      title: "Outstanding iOS app development",
      desc: "For businesses aiming to tap into the expanding Apple user base, our iOS app development services are tailored to meet your needs. We harness the prowess of Swift and the latest iOS frameworks to create sophisticated and captivating apps that seamlessly operate on iPhones, iPads, and other iOS devices.",
      image: DetailImg02,
    },
    {
      title: "Impeccable Android app solutions",
      desc: "Seize the opportunities within the expansive Android market through our Android app development solutions.Our team utilizes the flexibility of the Android platform, coupled with programming languages like Java or Kotlin, to craft robust and scalable apps tailored for the diverse Android user base.",
      image: DetailImg03,
    },
    {
      title: "Reach wider audience",
      desc: "Broaden your reach and optimize development efficiency with our proficiency in cross-platform app development. By employing frameworks such as React Native and Flutter, we design apps that seamlessly operate on both iOS and Android platforms, providing a time- and resource-saving solution for your development needs.",
      image: DetailImg04,
    },
    {
      title: "Exceptional design",
      desc: "We hold the conviction that outstanding design forms the foundation of a successful mobile app. Our team of skilled designers is dedicated to ensuring that your app not only boasts a visually stunning appearance but also delivers a delightful user experience. From intuitive interfaces to engaging interactions, we meticulously craft designs that captivate and retain users.",
      image: DetailImg05,
    },
    {
      title: "Quality assurance",
      desc: "For a flawless app performance, we implement thorough testing and quality assurance procedures. Our testing experts employ industry-standard techniques and methodologies to identify and rectify any issues, ensuring a seamless and bug-free user experience.",
      image: DetailImg06,
    },
  ];

  return (
    <>
      <ServiceBanner
        title="Mobile Applications"
        descData={BannerDescData}
        bannerImage={Mobile_IMG}
      />
      <ServiceDetails
        detailsArr={ServiceDetailsArr}
      />
      <TechStack techImages={techImages} />
      <SomeOfOurWork dataArr="Hawkeye, TackGPS, eTag, Hilyte, Coready, SpiritSwap" />
      <WorkWithUs mainTitle="Work with us to build" title="the best mobile app" />
    </>
  )
}

export default MobileApp;