import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import OddaMain from '../../../Assets/Images/SelectedWork/Odda/OddaTransparent.png';
import Odda1 from '../../../Assets/Images/SelectedWork/Odda/1.png';
import Odda2 from '../../../Assets/Images/SelectedWork/Odda/2.png';

const Odda = () => {

   const caseStudyData = {
      title: 'ODDA Magazine',
      content: `ODDA Magazine is a biannual, contemporary fashion and culture magazine based in New York and founded by current Creative Director and Editor in Chief David Martin in April 2012.`,
      galleryImages: [
         { url: Odda1 },
         { url: Odda2 },
      ],
      workDetails: "Our collaboration with ODDA Magazine marks a significant milestone in digital publishing, particularly in the realm of fashion. By developing a bespoke mobile application for digital subscriptions, we've empowered ODDA Magazine to extend its reach and engage with its audience in innovative ways. Our team's dedication to crafting a seamless and immersive user experience reflects our commitment to excellence in digital solutions. We're honored to have been entrusted with this project and are proud to contribute to ODDA Magazine's journey in redefining fashion media for the digital age.",
      outCome: "We are delighted to have collaborated with ODDA Magazine on this transformative project, revolutionizing the digital landscape of fashion publications. Our team's dedication and expertise have contributed to the seamless integration of digital subscriptions, enhancing user accessibility and engagement. The success of ODDA Magazine's digital platform underscores our commitment to delivering innovative solutions tailored to our clients' unique needs. We extend our sincerest congratulations to ODDA Magazine on their achievements and eagerly anticipate future collaborations to further elevate their digital presence.",
      year: '2020',
      client: 'ODDA',
      services: 'Mobile application Development, iOS, React Native, Flutter, Node.js',
      keyExpertise: 'Mobile application Development, Cross-plateform app, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${OddaMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Odda;
