import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import DbilMain from '../../../Assets/Images/SelectedWork/DBIL/dbil.jpeg';
import Dbil1 from '../../../Assets/Images/SelectedWork/DBIL/1.jpeg';
import Dbil2 from '../../../Assets/Images/SelectedWork/DBIL/2.jpeg';

const Dbil = () => {

   const caseStudyData = {
      title: 'DBIL',
      content: 'Direct Bazaar international Limited, is a India based direct selling company that commenced with solid principles and enduring vision and goal. They believe every people has tremendous potential and believing in unleashing their potential by empowering them with a stream of high income and enhancing their well-being through quality products and services.',
      galleryImages: [
         { url: Dbil1 },
         { url: Dbil2 },

      ],
      workDetails: `Technology has brought a new revolution introduced, it changed our life on a constantly accelerating speed. We a bright vision of combining the technology to emerge a business DBIL made a solid plan and projection to transform the nation, society, and individuals at a large scale, empowering society and growing the nation's economy through digital means.With our highly skilled experts help, we deployed our application development we connected them to the world digitally and an E- commerce website from where the can globally connect to the people and can accomplish their mission.With sleek smooth Design that anyone of any age can use and understand we removed the obstacle that was in between the DBIL’s mission.`,
      outCome: 'DBIL Indian based company leaving its remark over the world, they have won 35+ awards in the different segment along with that they have successfully acquired a spot in Guinness World Records and Limca Book of Records. As their aim and goal get closer day by day we take pride and feel proud to be a part of their story.',
      year: '2018',
      client: 'DBIL',
      services: 'Mobile Application Development, Web Development',
      keyExpertise: 'Mobile application Development, Manufacturing Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${DbilMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Dbil;
