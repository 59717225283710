import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import SpritswapMain from '../../../Assets/Images/SelectedWork/SpiritSwap/SpiritSwap.png';
import Spritswap1 from '../../../Assets/Images/SelectedWork/SpiritSwap/1.png';
import Spritswap2 from '../../../Assets/Images/SelectedWork/SpiritSwap/2.png';

const Spiritswap = () => {

   const caseStudyData = {
      title: 'Spirit Swap',
      content: `Spirit Swap is a decentralized exchange (DEX) on the Fantom Opera Chain. Spirit Swap's design is based on the Uniswap constant-product automated market maker (AMM). In an AMM, liquidity providers simply deposit a pair of tokens and an algorithm automatically makes markets for the token pair. Traders can easily swap between tokens in the AMM and get guaranteed rates for the swaps. Each swap on Spirit Swap incurs a fee, which gets distributed to liquidity providers as their payment for work.`,
      galleryImages: [
         { url: Spritswap1 },
         { url: Spritswap2 },
      ],
      workDetails: 'Spirit swap is decentralized finance. In it, we have created different types of smart contracts and integrated them. we have worked on a lot of technology in it, such as zapper, react js, solidity. It is a successful project that has become a lot of users nowadays. we are currently working on a new version with the Spirit swap team. In spirit swap, we have worked on many functions like Exchange, Bridge, Farm, land, pool, NFT.',
      outCome: 'We were obliged to work with them on such a project, and so proud to be a part of their success story. The CEO of Spirit Swap was impressed by our work delivered to them and was so happy to pair up with us for such a project, we wish such clients a lot more success to their career and look forward to work with them again.',
      year: '2021',
      client: 'Spirit Swap Finance',
      services: 'Blockchain, React.js, Mobile application Development, Smart contract Development',
      keyExpertise: 'Smart Contract Development, React.js, Mobile application Development, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${SpritswapMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Spiritswap;
