import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import metaverseBanner from "../../../../Assets/Images/Services/BannerImg/metaverseBanner.svg";

import metaverseImg1 from "../../../../Assets/Images/Services/ServiceDetails/Metaverse/metaverseImg1.png"
import metaverseImg2 from "../../../../Assets/Images/Services/ServiceDetails/Metaverse/metaverseImg2.png"
import metaverseImg3 from "../../../../Assets/Images/Services/ServiceDetails/Metaverse/metaverseImg3.png"
import metaverseImg4 from "../../../../Assets/Images/Services/ServiceDetails/Metaverse/metaverseImg4.png"
import metaverseImg5 from "../../../../Assets/Images/Services/ServiceDetails/Metaverse/metaverseImg5.png"

import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import btnSwift from '../../../../Assets/Images/Services/TechStack/btnSwift.svg'

const Metaverse = () => {

    const techImages = [
        Ethereum,
        Polygon,
        Binance,
        Tron,
        Near,
        QLDB,
        Metamask,
        Hyperledger,
        Solidity,
        Web3JS,
        ERC20,
        btnSwift,
    ];

    const BannerDescData = [
        {
            text: "With our proficiency in blockchain, augmented reality (AR), virtual reality (VR), and other emerging technologies, we guide you through the metaverse, unlocking its immense potential.",
        },
        {
            text: "We craft immersive metaverse experiences by harnessing cutting-edge technologies and innovative design approaches. Whether it's building virtual worlds or developing interactive avatars, our team brings your metaverse vision to vibrant life.",
        }
    ];

    const ServiceDetailsArr = [
        {
            title: "Build your own metaverse project",
            desc: "We utilize blockchain technology to offer secure and decentralized solutions within the metaverse. Whether you require asset tokenization, the creation of a digital economy, or assurance of trust and transparency, our team of blockchain experts has you covered.",
            image: metaverseImg1,
        },
        {
            title: "Augmented reality",
            desc: "We merge the real and virtual worlds through augmented reality (AR) experiences that elevate user engagement and interaction. Whether it's AR mobile apps or applications for AR glasses, we empower you to bridge the gap between physical and digital realities.",
            image: metaverseImg2,
        },
        {
            title: "Virtual reality",
            desc: "Engage your audience with captivating VR experiences that transport them to virtual realms. Whether it's crafting VR games, training simulations, or virtual tours, our VR solutions captivate and inspire.",
            image: metaverseImg3,
        },
        {
            title: "Cross platform",
            desc: "We guarantee seamless compatibility across various platforms and devices, granting users access to your metaverse from anywhere. Our cross-platform solutions ensure a unified and consistent user experience across web, mobile, and VR/AR devices.",
            image: metaverseImg4,
        },
        {
            title: "Explore new opportunities",
            desc: "The metaverse presents an incredible opportunity for businesses to engage with customers, create immersive experiences, and unlock new revenue streams.",
            image: metaverseImg5,
        },
    ];

    return (
        <>
            <ServiceBanner
                title="Metaverse"
                descData={BannerDescData}
                bannerImage={metaverseBanner}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to join" title="the metaverse" />
        </>
    )
}

export default Metaverse;