import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import MedisendMain from '../../../Assets/Images/SelectedWork/Medisend/MedisendTransparent.png';
import Medisend1 from '../../../Assets/Images/SelectedWork/Medisend/1.png';
import Medisend2 from '../../../Assets/Images/SelectedWork/Medisend/2.png';

const Medisend = () => {

   const caseStudyData = {
      title: 'Medisend',
      content: `A convenient tool to make medical file transfer securely and easier. This is an application that marries unparalleled blockchain technology with an access anywhere extremely user friendly interface that allows the transfer of medical documents securely from one Medisend user to another Medisend user within a closed community of medical professionals.`,
      galleryImages: [
         { url: Medisend1 },
         { url: Medisend2 },
      ],
      workDetails: "Our team is proud to have played a pivotal role in the development of the Medisend project, a groundbreaking platform designed to streamline the secure transfer of medical files. By integrating cutting-edge blockchain technology with an intuitive user interface, we have empowered medical professionals to seamlessly exchange critical documents within a secure and closed community. Our commitment to innovation and user-centric design has resulted in a convenient and efficient tool that enhances collaboration and communication among healthcare providers. Medisend represents the intersection of technology and healthcare, revolutionizing the way medical information is shared and accessed securely.",
      outCome: 'We were honored to collaborate on the Medisend project, a transformative platform revolutionizing medical file transfer. Our team takes immense pride in contributing to its success, delivering innovative solutions that enhance user experience and security. The CEO of Medisend was deeply impressed by our work, underscoring the importance of our partnership in achieving their objectives. We extend our best wishes to Medisend for continued success, eagerly anticipating future opportunities to collaborate and innovate together.',
      year: '2023',
      client: 'Medisend',
      services: 'Blockchain, Polygon, React.js, Smart contract Development',
      keyExpertise: 'Polygon, Smart Contract Development, React.js, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${MedisendMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl'>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Medisend;
