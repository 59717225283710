import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import automationBanner from "../../../../Assets/Images/Services/BannerImg/automation-banner.svg";

import automationImg1 from "../../../../Assets/Images/Services/ServiceDetails/AiAndAutomation/automationImg1.png"
import automationImg2 from "../../../../Assets/Images/Services/ServiceDetails/AiAndAutomation/automationImg2.png"
import automationImg3 from "../../../../Assets/Images/Services/ServiceDetails/AiAndAutomation/automationImg3.png"
import automationImg4 from "../../../../Assets/Images/Services/ServiceDetails/AiAndAutomation/automationImg4.png"

import Python from '../../../../Assets/Images/Services/TechStack/Python.svg'
import TensorFlow from '../../../../Assets/Images/Services/TechStack/TensorFlow.svg'
import PyTorch from '../../../../Assets/Images/Services/TechStack/PyTorch.svg'
import Pandas from '../../../../Assets/Images/Services/TechStack/Pandas.svg'
import Numpy from '../../../../Assets/Images/Services/TechStack/Numpy.svg'
import Matplotlib from '../../../../Assets/Images/Services/TechStack/Matplotlib.svg'
import Seaborn from '../../../../Assets/Images/Services/TechStack/Seaborn.svg'
import NLP from '../../../../Assets/Images/Services/TechStack/NLP.svg'
import OpenCV from '../../../../Assets/Images/Services/TechStack/OpenCV.svg'

const AiAndAutomation = () => {

    const BannerDescData = [
        {
            text: "We leverage the transformative potential of artificial intelligence (AI) and automation technologies to empower businesses across various industries.",
        },
        {
            text: "Our AI and automation services streamline processes, boost operational efficiency, and extract valuable insights from your data. Whether you seek to optimize workflows, automate repetitive tasks, or harness predictive analytics, our expert team is here to guide you through your AI journey.",
        },
    ];

    const ServiceDetailsArr = [
        {
            title: "Intelligent automation for efficiency and productivity",
            desc: "Harness our profound expertise in AI technologies and frameworks to pinpoint opportunities for automation, predictive analytics, and machine learning solutions.",
            image: automationImg1,
        },
        {
            title: "Chatbots and Virtual Assistants",
            desc: "Elevate customer service and support with intelligent chatbots and virtual assistants delivering personalized, round-the-clock assistance. Enhance customer engagement, minimize response times, and boost satisfaction through automated interactions.",
            image: automationImg2,
        },
        {
            title: "Machine Learning & Predictive Analytics",
            desc: "Leverage advanced machine learning algorithms and predictive analytics models to uncover hidden patterns and insights in your data. Harness AI's power to drive data-driven decisions, optimize processes, and gain a competitive edge in the market.",
            image: automationImg3,
        },
        {
            title: "Robotic Process Automation (RPA)",
            desc: "Achieve greater efficiency, accuracy, and cost savings by automating repetitive tasks and streamlining workflows with RPA. Free up valuable time for your team to concentrate on higher-value activities, while eliminating manual errors and accelerating task completion.",
            image: automationImg4,
        },
    ];

    const techImages = [
        Python,
        TensorFlow,
        PyTorch,
        Pandas,
        Numpy,
        Matplotlib,
        Seaborn,
        NLP,
        OpenCV,
    ];

    return (
        <>
            <ServiceBanner
                title="AI & Automation"
                descData={BannerDescData}
                bannerImage={automationBanner}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to build the best" title="data immutability" />
        </>
    )
}

export default AiAndAutomation;