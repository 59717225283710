import React from 'react'
import Networking from '../../Assets/Images/svg/networking.svg';
import Robot from '../../Assets/Images/svg/robot.svg';
import Wireless from '../../Assets/Images/svg/wireless.svg';
import CustomBackground from '../../Components/CustomBackground/CustomBackground';

const Platforms = () => {

   const DataArr = [
      {
         title: "Blockchain",
         image: Networking,
         desc: "Our Blockchain solution includes Decentralized Application Development, Defi, NFT Marketplace, Exchange Platform, smart contract, Mobile Wallet Development, and Blockchain App Development, covering a wide variety.",
      },
      {
         title: "Artificial Intelligence",
         image: Robot,
         desc: "Artificial Intelligence enhances the speed, precision, and effectiveness of human efforts, we deploy AI in IoT, blockchain, and other services to enhance the performance and give user delightful experience.",
      },
      {
         title: "Internet of Things",
         image: Wireless,
         desc: "Our IoT solutions have a great range, from integrating the device with an application to developing firmware that enables function we provide 360 solutions for this.",
      },
   ];

   return (
      <div className="relative flex justify-center items-center overflow-hidden px-8 mt-28 py-10">
         <div className="text-center max-w-7xl">
            <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
               <p className="text-3xl sm:text-4xl tracking-tight text-white">
                  Platforms
               </p>
               <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                  We give our clients the advantage of our library of the existing designs to speed up the development process.
               </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-14 mt-10 md:mt-14" data-aos="fade-up" data-aos-duration="900">
               {DataArr?.map((data, index) => {
                  return (
                     <div key={index} className="flex">
                        <CustomBackground>
                           <div className="flex flex-col text-start p-5 pb-14">
                              <img src={data?.image} className='float-left w-12' alt={`Expertise ${index}`} />
                              <p className="mt-3 text-2xl tracking-wide font-medium text-white">
                                 {data?.title}
                              </p>
                              <p className="mt-5 text-base leading-7 text-white-light">
                                 {data?.desc}
                              </p>
                           </div>
                        </CustomBackground>
                     </div>
                  )
               })}
            </div>

         </div>
      </div>
   )
}

export default Platforms;