import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import HawkeyeMain from '../../../Assets/Images/SelectedWork/Hawkeye/Hawkeye.png';
import Hawkeye1 from '../../../Assets/Images/SelectedWork/Hawkeye/1.jpeg';
import Hawkeye2 from '../../../Assets/Images/SelectedWork/Hawkeye/2.jpeg';
import Hawkeye3 from '../../../Assets/Images/SelectedWork/Hawkeye/3.jpeg';
import Hawkeye4 from '../../../Assets/Images/SelectedWork/Hawkeye/4.jpeg';

const Hawkeye = () => {

   const caseStudyData = {
      title: 'Hawkeye',
      content: 'Hawkeye as it’s in the name it says it all, a sleek design and provides safety as the new X1 Pro comes with a lot of new features. The application now allows users to track the car on the map and access the video from any corner around the city. Meet the new experience, powerful image sensor, new menu UI design, new OLED screen, and the list goes on. The Hawkeye is a new generation beast for those who love technology.',
      galleryImages: [
         { url: Hawkeye1 },
         { url: Hawkeye2 },
         { url: Hawkeye3 },
         { url: Hawkeye4 },
      ],
      workDetails: 'Using Caliente iTech’s Embedded Software Development engineering experience and Application Software Development expertise, the teams designed and prototyped an integrated system in a few months. We created prototypes, ran pilot testing, and studied, and prepared the application and system ready to go. The effort required deep expertise in Embedded Software and Application Software Development expertise. It includes the creation of UX/UI design, super-fast, smooth, and bug proof.',
      outCome: 'Hawkeye connect launched in February 2020 made a big buzz entering the market with their motto “Everything is much easier” - an amazing outcome for what we started as an internal learning and collaborative project between us and Hawkeye, proud to be associated with Hawkeye.',
      year: '2019',
      client: 'Hawkeye',
      services: 'Rapid Experiential Prototyping, Firmware Support, Mobile application Development',
      keyExpertise: 'Firmware & Embedded Development, Mobile application Development, Manufacturing Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${HawkeyeMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Hawkeye;
