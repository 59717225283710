import React, { useContext } from "react";
import { UserContext } from "../../../../Context/UserContext";
import Button from "../../../../Components/Button/Button";

const ServiceBanner = ({ title, descData, bannerImage }) => {

    const { setIsContactDrawerOpen } = useContext(UserContext);

    return (
        <div
            className="flex flex-col items-center justify-center mx-auto max-w-7xl px-8 py-12 md:py-28"
        // style={{ height: "calc(100vh - var(--header-height))" }}
        >
            <div className="flex flex-col md:flex-row gap-10">

                <div className="flex flex-col justify-center md:basis-1/2 gap-5">
                    <div className="flex flex-col sm:flex-row gap-5 items-center md:items-start justify-between basis-1/2 md:basis-auto">
                        <p className="text-4xl md:text-6xl font-semibold text-white">
                            {title}
                        </p>
                        <div className="flex justify-center md:hidden md:basis-1/2 my-10">
                            <img
                                src={bannerImage}
                                alt="banner"
                                className="w-[300px]"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        {
                            descData?.map((item, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="text-[17px] text-white-light leading-6"
                                    >
                                        {item?.text}
                                    </p>
                                )
                            })
                        }
                    </div>
                    <Button
                        text="Let's discuss your idea"
                        btnClass="mt-5 py-3 px-4"
                        btnType="white"
                        onClick={() => setIsContactDrawerOpen(true)}
                    />
                </div>

                <div className="md:flex justify-center hidden md:basis-1/2">
                    <img
                        src={bannerImage}
                        alt="banner"
                        className="w-[500px]"
                    />
                </div>

            </div>
        </div>
    )
}

export default ServiceBanner;