import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const CaseStudyDetails = ({ title, content, galleryImages, workDetails, outCome }) => {

   const [photoIndex, setPhotoIndex] = useState(0);
   const [isOpen, setIsOpen] = useState(false);

   const openLightbox = (index) => {
      setPhotoIndex(index);
      setIsOpen(true);
   };

   const closeLightbox = () => {
      setIsOpen(false);
   };

   return (
      <div className="page-content container single single-work text-white">
         <section className="intro row">
            <div className="col-sm-8 offset-sm-2 col-xs-12">
               <p className="text-1xl text-gray-500 uppercase mt-4">CASE STUDY</p>
               <span className="text-3xl sm:text-4xl pt-3 tracking-tight text-white block mt-4">{title}</span>
            </div>
         </section>

         <section className="content-basic--row row py-4">
            <div className='tracking-tight border-t border-slate-dark'></div>
            <div className="max-w-2xl mt-4 text-md tracking-tight">
               <div>
                  <p className="mt-5 text-base leading-7 text-white-light">{content}</p>
               </div>
            </div>
         </section>

         <section data-aos="fade-up" data-aos-duration="900">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mt-8">
               {galleryImages?.map((image, index) => (
                  <div key={index} onClick={() => openLightbox(index)}>
                     <img src={image?.url} alt={`Gallery ${index}`} className="w-full h-auto cursor-pointer hover:opacity-75" />
                  </div>
               ))}
            </div>

            {isOpen && (
               <Lightbox
                  mainSrc={galleryImages[photoIndex]?.url}
                  nextSrc={galleryImages[(photoIndex + 1) % galleryImages?.length]?.url}
                  prevSrc={galleryImages[(photoIndex + galleryImages?.length - 1) % galleryImages?.length]?.url}
                  onCloseRequest={closeLightbox}
                  onMovePrevRequest={() => setPhotoIndex((photoIndex + galleryImages?.length - 1) % galleryImages?.length)}
                  onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryImages?.length)}
               />
            )}
         </section>

         <section className="content-basic--row row" data-aos="fade-up" data-aos-duration="900">
            <div className="flex flex-col text-start mt-10 md:mt-14">
               <p className="mt-3 text-2xl tracking-wide font-medium text-white">
                  The Work
               </p>
               <p className="mt-5 text-base leading-7 text-white-light">
                  {workDetails}
               </p>
            </div>
         </section>

         <section className="content-basic--row row" data-aos="fade-up" data-aos-duration="900">

            <div className="flex flex-col text-start mt-10 md:mt-14">
               <p className="mt-3 text-2xl tracking-wide font-medium text-white">
                  The Outcome
               </p>
               <p className="mt-5 text-base leading-7 text-white-light">
                  {outCome}
               </p>
            </div>
         </section>
      </div>
   );
};

export default CaseStudyDetails;
