import React from "react";
import Marquee from "react-fast-marquee";
import dbil_logo from '../../../Assets/Images/png/dbil_logo.png';
import Evolve from '../../../Assets/Images/png/Evolve.png';
import Hilyte from '../../../Assets/Images/png/Hilyte.png';
import Odda from '../../../Assets/Images/png/Odda.png';
import eTag_logo from '../../../Assets/Images/png/eTag_logo.png';
import privatex_Logo from '../../../Assets/Images/png/privatex_Logo.png';
import sennheiser from '../../../Assets/Images/png/sennheiser.png';
import spiritswap from '../../../Assets/Images/png/spiritswap.png';
import tack from '../../../Assets/Images/png/tack.png';
import fantomdao from '../../../Assets/Images/png/fantomdao.png';
import vanityteen from '../../../Assets/Images/png/vanityteen.png';

const Projects = () => {

    const images = [
        sennheiser,
        Hilyte,
        dbil_logo,
        eTag_logo,
        privatex_Logo,
        tack,
        Evolve,
        Odda,
        spiritswap,
        fantomdao,
        vanityteen,
    ];

    return (
        <div className="flex flex-col items-center justify-center w-full">
            <p className="relative text-center sm:font-semibold mt-8 text-lg text-white-light">
                Trusted by World's leading Organization
            </p>
            <div className="relative overflow-hidden h-16 mt-6 w-full">
                <div className="flex gap-10 sm:gap-28">
                    <Marquee>
                        <section className='flex justify-center items-center'>
                            {images?.map((image, index) => (
                                <img key={index} className="max-h-14 w-auto max-w-36 ml-10 sm:ml-28" src={image} alt={index} />
                            ))}
                        </section>
                    </Marquee>
                </div>
            </div>
        </div>
    );
}

export default Projects;