import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import FantomDaoMain from '../../../Assets/Images/SelectedWork/FantomDao/FantomDao.svg';
import FantomDao1 from '../../../Assets/Images/SelectedWork/FantomDao/1.png';
import FantomDao2 from '../../../Assets/Images/SelectedWork/FantomDao/2.png';

const FantomDao = () => {

   const caseStudyData = {
      title: 'Fantom Dao',
      content: `Fantom DAO is the Go-to DeFi 2.0 for the Fantom network to grow the Fantom ecosystem and share the profits with the community while utilizing the speed, security, and scalability that Fantom Offers. Welcome to FantomDAO, a magical world where dreams come true.`,
      galleryImages: [
         { url: FantomDao1 },
         { url: FantomDao2 },
      ],
      workDetails: 'In the Fantom DAO project, our team has undertaken a comprehensive array of tasks aimed at enhancing the platforms functionality and user experience. From optimizing smart contract protocols to improving transaction speeds and security measures, our efforts have centered around refining the core infrastructure of the Fantom network. We have collaborated closely with stakeholders to integrate innovative features, such as governance mechanisms and voting systems, fostering a more inclusive and participatory ecosystem. Additionally, our commitment to scalability and interoperability has driven the implementation of cross-chain functionalities, enabling seamless interactions with external networks. Through our dedicated development initiatives, we aspire to propel the Fantom DAO project towards greater efficiency, resilience, and user engagement in the dynamic landscape of decentralized finance.',
      outCome: 'We are honored to have contributed to the development of the Fantom DAO project and are immensely proud to have been a part of its journey. Collaborating with the Fantom DAO team has been a rewarding experience, and we are delighted that our contributions have positively impacted their success. The team at Fantom DAO has expressed satisfaction with the outcomes of our work, and we are grateful for the opportunity to have partnered with them on this significant project. We extend our best wishes to the Fantom DAO team for continued success, and we eagerly anticipate the possibility of collaborating with them again in the future.',
      year: '2021',
      client: 'Fantom Dao',
      services: 'Blockchain, React.js, Mobile application Development, Smart contract Development',
      keyExpertise: 'Smart Contract Development, React.js, Mobile application Development, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${FantomDaoMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default FantomDao;
