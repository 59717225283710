import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import DataImmutability_IMG from "../../../../Assets/Images/Services/BannerImg/data-immutability-banner.svg";
import DataImmutabilityImg1 from "../../../../Assets/Images/Services/ServiceDetails/DataImmutability/DataImmutabilityImg1.svg"
import DataImmutabilityImg2 from "../../../../Assets/Images/Services/ServiceDetails/DataImmutability/DataImmutabilityImg2.svg"
import DataImmutabilityImg3 from "../../../../Assets/Images/Services/ServiceDetails/DataImmutability/DataImmutabilityImg3.svg"

import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import btnSwift from '../../../../Assets/Images/Services/TechStack/btnSwift.svg'

const DataImmutability = () => {

    const BannerDescData = [
        {
            text: "Tech Alchemy stands at the forefront as the premier provider of data immutability services driven by smart contracts.",
        },
        {
            text: "Leveraging our proficiency in blockchain technology and smart contract development, we empower businesses to safeguard the integrity and security of their data.",
        },
        {
            text: "Our expert blockchain developers create tailored smart contracts for your needs, ensuring transparent, tamper-proof agreements for secure transactions.",
        },
    ];

    const ServiceDetailsArr = [
        {
            title: "Enhance trust, transparency and security",
            desc: "We specialize in securely storing and managing critical data through blockchain technology. Leveraging decentralized networks and cryptographic algorithms, we guarantee the immutability of your data, making it resistant to unauthorized modifications or tampering. This ensures the integrity and security of your valuable information at all times.",
            image: DataImmutabilityImg1,
        },
        {
            title: "Smart contracts for regulatory compliance",
            desc: "We aid businesses in achieving regulatory compliance by implementing smart contracts that conform to industry-specific standards and regulatory frameworks.",
            image: DataImmutabilityImg2,
        },
        {
            title: "Secure DApps powered by smart contracts",
            desc: "Our expertise lies in crafting decentralized applications (DApps) that utilize smart contracts for secure and transparent interactions. While smart contracts guarantee data immutability and integrity on the blockchain, our DApps go further, integrating features like user account protection, sensitive information encryption, and secure communication protocols. This ensures top-tier data integrity and security.",
            image: DataImmutabilityImg3,
        },
    ];

    const techImages = [
        Ethereum,
        Polygon,
        Binance,
        Tron,
        Near,
        QLDB,
        Metamask,
        Hyperledger,
        Solidity,
        Web3JS,
        ERC20,
        btnSwift,
    ];

    return (
        <>
            <ServiceBanner
                title="Data Immutability (Smart Contracts)"
                descData={BannerDescData}
                bannerImage={DataImmutability_IMG}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <SomeOfOurWork dataArr="SpiritSwap" />
            <WorkWithUs mainTitle="Work with us to build the best" title="data immutability" />
        </>
    )
}

export default DataImmutability;