import React, { useContext, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import emailjs from '@emailjs/browser';
import { UserContext } from "../../Context/UserContext";
import phone from '../../Assets/Images/png/phone.png'
import Input from "../../Components/Input/Input";
import Loader from "../../Components/Loader/Loader";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Alert from "../../Components/Alert/Alert";
import useForm from "../../Hooks/useForm";
import Button from "../../Components/Button/Button";

const budgetOptions = [
    { value: 'notSure', name: 'I’m not sure' },
    { value: '10KTo30K', name: '$10K - $30K' },
    { value: '30KTo60K', name: '$30K - $60K' },
    { value: '60KTo120K', name: '$60K - $120K' },
    { value: '120K+', name: '$120K+' },
];

const ContactDrawer = () => {

    const drawerRef = useRef(null);
    const recaptchaRef = useRef(null);
    const { isContactDrawerOpen, setIsContactDrawerOpen } = useContext(UserContext);
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState({
        number: '',
        dialCode: '+91',
    });

    const validateForm = () => {
        let error = {};
        if (!values?.name?.trim()) {
            error.name = "Name is required";
        } else if (!/^[a-z A-Z.]*$/.test(values?.name)) {
            error.name = "Name is invalid";
        }
        if (!values?.email?.trim()) {
            error.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values?.email)) {
            error.email = "Email is invalid";
        }
        if (!phoneNumber?.number) {
            error.phone = "Phone number is required";
        } else if (!/^(?:\+?\d[- ()]?){7,15}$/.test(phoneNumber?.number)) {
            error.phone = "Phone number is invalid";
        }
        if (!values?.budget?.trim()) {
            error.budget = "Please select your budget";
        }
        if (!values?.help?.trim()) {
            error.help = "This field is required";
        }
        return error;
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef?.current && !drawerRef?.current?.contains(event?.target) && window.innerWidth > 768) {
                setIsContactDrawerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isContactDrawerOpen && window.innerWidth < 640) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isContactDrawerOpen]);

    const handlePhoneNumberChange = (value, country) => {

        const dialCodeLength = country?.dialCode?.length;
        const extractedDialCode = value?.substring(0, dialCodeLength);
        const phoneNumberDigits = value?.substring(dialCodeLength)?.replace(/\D/g, '');

        setPhoneNumber({
            number: phoneNumberDigits,
            dialCode: extractedDialCode
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            phone: ""
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors)?.length === 0) {
            if (isCaptchaVerified) {
                setIsLoading(true);

                // Your EmailJS service ID, template ID, and user ID
                const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
                const templateId = process.env.REACT_APP_EMAILJS_CONTACT_DRAWER_TEMPLATE_ID;
                const userId = process.env.REACT_APP_EMAILJS_USER_ID;

                // Send email using EmailJS
                emailjs.sendForm(serviceId, templateId, e.target, userId)
                    .then((response) => {
                        setIsLoading(false);
                        setAlert({ show: true, type: "success", message: "Congratulations! Your inquiry has been received. Our team is on it, and you can expect to hear from us shortly. In the meantime, feel free to explore more about our products/services." });
                        setValues({
                            name: "",
                            email: "",
                            help: ""
                        });
                        setPhoneNumber(prevState => ({
                            ...prevState,
                            number: ""
                        }));
                        setErrors({});
                        setIsCaptchaVerified(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        // console.error("Error sending email:", error);
                        setAlert({ show: true, type: "error", message: "There was an error submitting the form." });
                    });
                // Reset reCAPTCHA component
                if (recaptchaRef?.current) {
                    recaptchaRef?.current?.reset();
                }
            } else {
                setErrors({ reCaptcha: 'Please complete the reCAPTCHA verification.' });
            }
        } else {
            setErrors(formErrors);
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        isCaptchaVerified,
        setIsCaptchaVerified,
        handleInputChange,
        handleReCaptchaVerify,
        handleSubmit,
    } = useForm(onSubmit);

    const IconBtnClass = `fixed justify-center items-center z-[1000] rounded-full drawerAnimation bg-grey text-white
                            w-[54px] h-[54px] bottom-[85px] xs:w-[60px] xs:h-[60px] xs:bottom-[100px]
                            ${isContactDrawerOpen ? 'right-[480px]' : 'right-[14px] xs:right-[24px]'}`;

    return (
        <div className="relative ContactDrawer" ref={drawerRef}>

            {
                isContactDrawerOpen ?
                    <button
                        className={`hidden sm:flex ${IconBtnClass}`}
                        onClick={() => {
                            setIsContactDrawerOpen(!isContactDrawerOpen);
                            setAlert({ show: false, type: "", message: "" });
                        }}
                    >
                        <Icon path={mdiClose} size={1.3} />
                    </button>
                    :
                    <button
                        className={`flex ${IconBtnClass}`}
                        onClick={() => { setIsContactDrawerOpen(!isContactDrawerOpen) }}
                    >
                        {/* <Icon path={mdiRocket} size={1.5} /> */}
                        <img src={phone} alt="phone" className="w-8 h-8 mr-0.5 mt-0.5" />
                    </button>
            }

            <div className={`w-full sm:w-[480px] p-5 sm:p-8 z-[1000] fixed h-full top-0 overflow-y-auto drawerAnimation bg-black 
                                ${isContactDrawerOpen ? 'sm:-right-1' : '-right-[650px]'}`}>

                <div className="flex justify-end sm:hidden">
                    <Icon path={mdiClose} size={1.5} className="text-white" onClick={() => { setIsContactDrawerOpen(!isContactDrawerOpen) }} />
                </div>

                <p className="mt-4 text-xl tracking-wide font-semibold text-white">
                    Let’s Get In Touch
                </p>


                <Alert alert={alert} setAlert={setAlert} />
                <div className="mt-10">
                    <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            label="Name"
                            id="name"
                            name="name"
                            value={values?.name || ""}
                            onChange={handleInputChange}
                            required
                            error={errors?.name}
                        />
                        <Input
                            type="email"
                            label="Your work email"
                            id="email"
                            name="email"
                            value={values?.email || ""}
                            onChange={handleInputChange}
                            required
                            error={errors?.email}
                        />
                        {/* <Input
                            type="text"
                            label="Phone"
                            id="phone"
                            name="phone"
                            value={values?.phone || ""}
                            onChange={handleInputChange}
                            required
                            error={errors?.phone}
                        /> */}

                        <div className="flex flex-col gap-3">
                            <label htmlFor="phone" className="text-white-light">
                                Phone
                            </label>
                            <PhoneInput
                                country={'in'}
                                inputProps={{
                                    id: 'phone',
                                    name: 'phone',
                                    required: true,
                                }}
                                // value={phoneNumber}
                                value={`${phoneNumber?.dialCode} ${phoneNumber?.number}`}
                                onChange={handlePhoneNumberChange}
                                enableSearch
                                disableSearchIcon
                                countryCodeEditable={false}
                                searchNotFound="No country found!"
                                inputStyle={{
                                    width: '100%',
                                    outline: 'none',
                                    background: 'transparent',
                                    borderBottom: '1px solid #3f3f46',
                                    fontSize: '1rem',
                                    color: 'white',
                                    borderRadius: '0',
                                    borderTop: 'none',
                                    borderRight: 'none',
                                    borderLeft: 'none'
                                }}
                                dropdownStyle={{
                                    background: '#111827',
                                    color: '#fff',
                                    maxHeight: '270px'
                                }}
                                searchStyle={{
                                    background: '#111827',
                                    marginLeft: 'auto',
                                    width: '100%',
                                    border: '1px solid #3f3f46'
                                }}
                            />
                            {
                                errors?.phone &&
                                <p className="text-red-600">{errors?.phone}</p>
                            }
                        </div>

                        <div className="flex flex-col gap-3">
                            <label htmlFor="budget" className="text-white-light">
                                Your budget
                            </label>
                            <Dropdown
                                id="budget"
                                name="budget"
                                value={values?.budget || ""}
                                onChange={(e) => handleInputChange(e, "budget")}
                                Options={budgetOptions}
                                setErrors={errors?.budget}
                            />
                            {
                                errors?.budget &&
                                <p className="text-red-600">{errors?.budget}</p>
                            }
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="help" className="text-white-light">
                                How can we help?
                            </label>
                            <textarea
                                className="py-1 w-full focus:outline-none bg-transparent border-b border-zinc text-white"
                                id="help"
                                name="help"
                                rows="2"
                                value={values?.help || ""}
                                onChange={handleInputChange}
                                required
                                error={errors?.help}
                            />
                            {
                                errors?.help &&
                                <p className="text-red-600">{errors?.help}</p>
                            }
                        </div>

                        <div className="mt-3">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                onChange={handleReCaptchaVerify}
                                theme="dark"
                            />
                            {
                                errors?.reCaptcha &&
                                <p className="text-red-600">{errors?.reCaptcha}</p>
                            }

                            <Button
                                text={isLoading ? <Loader /> : "Submit"}
                                btnClass="mt-5"
                                btnType="white"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactDrawer;