import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import Gaming_IMG from "../../../../Assets/Images/Services/BannerImg/gaming-banner.svg";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import GamingImg01 from "../../../../Assets/Images/Services/ServiceDetails/Gaming/gaming-service1.svg"
import GamingImg02 from "../../../../Assets/Images/Services/ServiceDetails/Gaming/gaming-service2.svg"
import GamingImg03 from "../../../../Assets/Images/Services/ServiceDetails/Gaming/gaming-service3.png"
import GamingImg04 from "../../../../Assets/Images/Services/ServiceDetails/Gaming/gaming-service4.svg"
import GamingImg05 from "../../../../Assets/Images/Services/ServiceDetails/Gaming/gaming-service5.svg"


import Angular from '../../../../Assets/Images/Services/TechStack/Angular.svg'
import Reactt from '../../../../Assets/Images/Services/TechStack/React.svg'
import NextJS from '../../../../Assets/Images/Services/TechStack/NextJS.svg'
import Nativescript from '../../../../Assets/Images/Services/TechStack/Nativescript.svg'
import NodeJS from '../../../../Assets/Images/Services/TechStack/NodeJS.svg'
import PHP from '../../../../Assets/Images/Services/TechStack/PHP.svg'
import Typescript from '../../../../Assets/Images/Services/TechStack/Typescript.svg'
import Python from '../../../../Assets/Images/Services/TechStack/Python.svg'
import Express from '../../../../Assets/Images/Services/TechStack/Express.svg'
import Nest from '../../../../Assets/Images/Services/TechStack/Nest.svg'
import KOA from '../../../../Assets/Images/Services/TechStack/KOA.svg'
import IOS from '../../../../Assets/Images/Services/TechStack/IOS.svg'
import android from '../../../../Assets/Images/Services/TechStack/android.svg'
import unityimg from '../../../../Assets/Images/Services/TechStack/unity-img.svg'
import MongoDB from '../../../../Assets/Images/Services/TechStack/MongoDB.svg'
import Postgre from '../../../../Assets/Images/Services/TechStack/Postgre.svg'
import AWS from '../../../../Assets/Images/Services/TechStack/AWS.svg'

const Gaming = () => {

   const techImages = [
      Angular,
      Reactt,
      NextJS,
      Nativescript,
      NodeJS,
      PHP,
      Typescript,
      Python,
      Express,
      Nest,
      KOA,
      IOS,
      android,
      unityimg,
      MongoDB,
      Postgre,
      AWS,
   ];

   const BannerDescData = [
      {
         text: "We merge our proficiency in mobile and Web3 gaming to transform and elevate gaming encounters.",
      },
      {
         text: "Discover the boundless potential of mobile gaming and innovation in decentralized technologies through our diverse array of services crafted to cater to your unique requirements.",
      }
   ];

   const ServiceDetailsArr = [
      {
         title: "We create exceptional gaming experiences",
         desc: "Dive into the forefront of the mobile gaming revolution with our state-of-the-art mobile gaming solutions. Our expertise lies in crafting engaging mobile games tailored for both iOS and Android platforms, designed to mesmerize audiences through seamless user experiences, captivating visuals, and irresistible gameplay. Whether you're a burgeoning startup or a seasoned gaming enterprise, our mobile gaming solutions are crafted to enchant players and propel you toward accomplishing your business objectives.",
         image: GamingImg01,
      },
      {
         title: "Game monetisation",
         desc: "Unlock your maximum revenue potential with our game monetization strategies. We assist you in pinpointing and integrating effective monetization models such as in-app purchases, advertisements, subscriptions, and beyond. Our objective is to establish a sustainable revenue stream, all while maintaining a harmonious and enjoyable gaming experience for your players.",
         image: GamingImg02,
      },
      {
         title: "Build web3 games",
         desc: "Bring your web3 game concepts to life with our adept team of developers, ensuring not only engaging gameplay but also emphasizing interoperability and player-driven economies.",
         image: GamingImg03,
      },
      {
         title: "NFT integration",
         desc: "Leverage the potential of Non-Fungible Tokens (NFTs) to bring added value and uniqueness to your gaming encounters. We aid in seamlessly incorporating NFT functionality into your games, empowering players to possess, exchange, and amass virtual assets. From the creation and minting of NFTs to their integration into game mechanics, we guarantee a smooth and secure NFT integration, elevating player engagement and opening up new avenues for monetization.",
         image: GamingImg04,
      },
      {
         title: "Provable fairness and transparent reward distribution",
         desc: "Harness the capabilities of smart contracts to establish secure and transparent gaming ecosystems. Our proficient blockchain developers craft personalized smart contracts governing game mechanics, in-game economies, and player interactions. Through smart contracts, you can instill trust, facilitate provable fairness, and guarantee transparent reward distribution, revolutionizing the gaming experience.",
         image: GamingImg05,
      },
   ];

   return (
      <>
         <ServiceBanner
            title="Gaming"
            descData={BannerDescData}
            bannerImage={Gaming_IMG}
         />
         <ServiceDetails
            detailsArr={ServiceDetailsArr}
         />
         <TechStack techImages={techImages} />
         <WorkWithUs
            mainTitle="Work with us to unlock new" title="possibilities in web3 gaming" />
      </>
   )
}

export default Gaming;