import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import NFTs_IMG from "../../../../Assets/Images/Services/BannerImg/nfts-banner.svg";

import nftsImg1 from "../../../../Assets/Images/Services/ServiceDetails/NFTs/nftsImg1.svg"
import nftsImg2 from "../../../../Assets/Images/Services/ServiceDetails/NFTs/nftsImg2.svg"
import nftsImg3 from "../../../../Assets/Images/Services/ServiceDetails/NFTs/nftsImg3.svg"

import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import btnSwift from '../../../../Assets/Images/Services/TechStack/btnSwift.svg'

const NFTs = () => {

    const techImages = [
        Ethereum,
        Polygon,
        Binance,
        Tron,
        Near,
        QLDB,
        Metamask,
        Hyperledger,
        Solidity,
        Web3JS,
        ERC20,
        btnSwift,
    ];

    const BannerDescData = [
        {
            text: "Our suite of NFT development solutions is tailored to empower you in harnessing the full potential of this dynamic technology.",
        },
        {
            text: "Regardless of whether you're an artist, creator, or business, our development team provides comprehensive services covering every stage of the NFT lifecycle. From artwork and music to virtual real estate and gaming assets, NFTs are transforming the landscape of digital ownership.",
        }
    ];

    const ServiceDetailsArr = [
        {
            title: "Digital assets, unique collectibles, & decentralised marketplaces",
            desc: "Utilize our proficiency in blockchain and smart contract development to craft your own NFTs. From token design and minting to metadata integration, our team guarantees the security, uniqueness, and seamless blockchain integration of your NFTs.",
            image: nftsImg1,
        },
        {
            title: "White label NFT marketplaces",
            desc: "Seize the opportunities within the expanding NFT marketplace ecosystem and establish your presence. We specialize in building decentralized marketplaces that empower creators, collectors, and investors to securely and efficiently buy, sell, and trade NFTs.",
            image: nftsImg2,
        },
        {
            title: "Seamless integration",
            desc: "Incorporate NFT functionality seamlessly into your current platforms or applications. Collaborating closely with you, our team will pinpoint the optimal integration strategies to guarantee a smooth user experience for your audience.",
            image: nftsImg3,
        },
    ];

    return (
        <>
            <ServiceBanner
                title="Non-Fungible Tokens (NFTs)"
                descData={BannerDescData}
                bannerImage={NFTs_IMG}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <SomeOfOurWork dataArr="SpiritSwap" />
            <WorkWithUs mainTitle="Work with us to unlock new possibilities in" title="digital ownership and creativity" />
        </>
    )
}

export default NFTs;