import React, { useContext } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../Context/UserContext";
import Button from "../../../../Components/Button/Button";
import CustomBackground from "../../../../Components/CustomBackground/CustomBackground";

const SomeOfOurWork = ({ dataArr }) => {

    const navigate = useNavigate();
    const { OurWorkArr } = useContext(UserContext);

    const filteredWorkArr = OurWorkArr?.filter(work => dataArr?.includes(work?.id));

    return (
        <div className="px-5 py-10 md:py-20 mb-20 mx-auto max-w-7xl" data-aos="fade-up" data-aos-duration="900">
            <CustomBackground className="relative py-16 xl:px-16 overflow-hidden">
                <div className="relative z-10">
                    <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                        <p className="text-3xl sm:text-4xl tracking-tight text-white">
                            Some of Our work
                        </p>
                    </div>

                    <div className="flex items-center gap-6 md:gap-12 mt-10 md:mt-24 OurWork">
                        <div className="swiper-button-prev"></div>
                        <Swiper
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                            }}
                            loop={filteredWorkArr?.length > 1 ? true : false}
                            // loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                            modules={[Navigation, Pagination]}
                            style={{
                                paddingBottom: "60px",
                            }}
                        >
                            {
                                filteredWorkArr?.map((data, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-20 items-center">
                                            <div className="col-span-1">
                                                <img
                                                    src={data?.image}
                                                    alt="detailImg"
                                                    className="rounded md:w-full h-auto"
                                                />
                                            </div>
                                            <div className="col-span-1 flex flex-col gap-5 justify-center">
                                                <p className="text-3xl tracking-wide font-semibold text-white">
                                                    {data?.name}
                                                </p>
                                                <p className="font-medium text-white-light">
                                                    {data?.desc}
                                                </p>
                                                <Button
                                                    text="Read More"
                                                    onClick={() => navigate(data?.redirectTo)}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>
            </CustomBackground>
        </div>
    )
}

export default SomeOfOurWork;