import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import dataTransformationBanner from "../../../../Assets/Images/Services/BannerImg/dataTransformation-banner.svg";

import dataTransformationImg1 from "../../../../Assets/Images/Services/ServiceDetails/DigitalTransformation/dataTransformationImg1.svg"
import dataTransformationImg2 from "../../../../Assets/Images/Services/ServiceDetails/DigitalTransformation/dataTransformationImg2.svg"

import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import btnSwift from '../../../../Assets/Images/Services/TechStack/btnSwift.svg'

const DigitalTransformation = () => {

    const BannerDescData = [
        {
            text: "As a technology development partner, we collaborate with businesses seeking to modernize their operations through technology. Through our digital transformation approach, we empower companies to optimize efficiencies and excel in the technology age.",
        },
        {
            text: "Our seasoned team collaborates closely with you to craft a tailored technology strategy aligned with your business goals and objectives. We adopt a diagnostic, problem-led approach to optimizing technology. By analyzing your current processes, identifying areas for enhancement, and designing a roadmap for seamless implementation, we ensure minimal disruption.",
        },
    ];

    const ServiceDetailsArr = [
        {
            title: "We are digital transformation specialists",
            desc: "We're more than just a development agency. Our team of top-tier developers and project managers delve deep into your technical objectives, akin to how a CTO would approach them.",
            image: dataTransformationImg1,
        },
        {
            title: "Modernise your operations",
            desc: "Harness our profound comprehension of both traditional and emerging technologies to unlock new opportunities for your business. Whether it's blockchain, artificial intelligence, cloud computing, or data analytics, we assist you in driving efficiency, enhancing customer experiences, and fueling innovation.",
            image: dataTransformationImg2,
        },
    ];

    const techImages = [
        Ethereum,
        Polygon,
        Binance,
        Tron,
        Near,
        QLDB,
        Metamask,
        Hyperledger,
        Solidity,
        Web3JS,
        ERC20,
        btnSwift,
    ];

    return (
        <>
            <ServiceBanner
                title="Digital Transformation"
                descData={BannerDescData}
                bannerImage={dataTransformationBanner}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to transform" title="your business digitally" />
        </>
    )
}

export default DigitalTransformation;