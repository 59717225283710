import React from "react";
import CustomBackground from "../../../Components/CustomBackground/CustomBackground";
import bgImage1 from '../../../Assets/Images/svg/BgImage1.svg'
import bgImage2 from '../../../Assets/Images/svg/BgImage2.svg'
import bgImage3 from '../../../Assets/Images/svg/BgImage3.svg'

const AboutUs = () => {

    const DataArr = [
        {
            title: "Team of experts",
            desc: "We take advantage of our team of experts to sort out the solid problems of our clients.",
            image: bgImage1,
        },
        {
            title: "Collaboration and partnership",
            desc: "We also join forces, do collaboration and partnership over the projects and also provide our guidance and assistance for the product development process and launching.",
            image: bgImage2,
        },
        {
            title: "Innovation-driven",
            desc: "We seek problems and try to solve it through new innovation and keep on taking feedbacks to make things better and better.",
            image: bgImage3,
        },
    ];

    return (
        <div className="px-8 mx-auto max-w-7xl">

            <div className="text-center mt-32 sm:mt-44">
                <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                    <span className="text-3xl sm:text-4xl tracking-tight text-white">
                        About <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                            Us
                        </span>
                    </span>
                    <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                        We seek business and startups with creative and innovative concepts and assists them to have a breakthrough by connecting their ideas with reality
                    </p>
                </div>

                <div className="grid md:grid-cols-3 md:gap-14 mt-12 md:mt-24" data-aos="fade-up" data-aos-duration="900">
                    {DataArr?.map((data, index) => {
                        return (
                            <div key={index} className="flex">
                                <CustomBackground bgImage={data?.image}>
                                    <div className="flex flex-col text-start p-5 pb-14">
                                        <p className="mt-3 text-2xl tracking-wide font-medium text-white">
                                            {data?.title}
                                        </p>
                                        <p className="mt-5 text-base leading-7 text-white-light">
                                            {data?.desc}
                                        </p>
                                    </div>
                                </CustomBackground>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AboutUs;