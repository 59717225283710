import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Navigation } from 'swiper/modules';

import JenniferCRamirez from '../../Assets/Images/clients/Jennifer-C-Ramirez.jpeg';
import JustinZhang from '../../Assets/Images/clients/Justin-Zhang.png';
import namrataRakholiya from '../../Assets/Images/clients/namrata-rakholiya.png';
import GrahamKelly from '../../Assets/Images/clients/Graham-Kelly.png';
import BriacBarthes from '../../Assets/Images/clients/Briac-Barthes.png';
import mardosoo from '../../Assets/Images/clients/mardo-soo.png';
import NoumenAnastasia from '../../Assets/Images/clients/Noumen-Anastasia.png';
import JustinHorrocks from '../../Assets/Images/clients/Justin-Horrocks.png';

import dbil_logo from '../../Assets/Images/png/dbil_logo.png';
import Hilyte from '../../Assets/Images/png/Hilyte.png';
import eTag_logo from '../../Assets/Images/png/eTag_logo.png';
import privatex_Logo from '../../Assets/Images/png/privatex_Logo.png';
import NYC from '../../Assets/Images/png/nyc.png';
import tack from '../../Assets/Images/png/tack.png';

const ClientReviews = () => {

    const DataArr = [
        {
            review: `"You meet people for work and ends with special feelings and not because of me, because of him. This guy worked too hard, friendly, and beautiful. He has patience till the moon and back. And too important for that, he know his work, he knows what he's doing. If you can pay whatever for his work, for you, do that. Fully recommended !!!"`,
            clientName: 'Jennifer C-Ramírez',
            position: 'CEO, Culottis Butic',
            clientImg: JenniferCRamirez,
        },
        {
            review: `"Caliente iTech took care of the PCB development and testing for our devices, and their competence, as well as his hard-work, are fantastic! They always know how to test and find answers, and designs very efficient electronic boards that fit very well the expectations. They are very reactive and always do their best to make sure we get their work as soon as possible."`,
            clientName: 'Justin Zhang',
            position: 'CEO, Tack',
            clientImg: JustinZhang,
            companyLogo: tack,
        },
        {
            review: `"Very cooperative and patient, working with them was so smooth as they don’t just focus on technical work but also focuses on the management side."`,
            clientName: 'Namrata Rakholiya',
            position: 'Chairman, DBIL',
            clientImg: namrataRakholiya,
            companyLogo: dbil_logo,
        },
        {
            review: `"We showed our problem which we were facing for 1 year to the cambridge university dons as well, but you guys were able to track it!"`,
            clientName: 'Graham Kelly',
            position: 'Technical Director, eTag',
            clientImg: GrahamKelly,
            companyLogo: eTag_logo,
        },
        {
            review: `"Outstanding service and focuses on customer's needs, requirements, and how to fulfill them.These people are the gem."`,
            clientName: 'Briac Barthes',
            position: 'Co-Founder & CEO, Hilyte',
            clientImg: BriacBarthes,
            companyLogo: Hilyte,
        },
        {
            review: `"Caliente itech delivered outstanding work on app development! Definitely recommended working with!"`,
            clientName: 'Mardo Soo',
            position: 'CEO, PrivateX',
            clientImg: mardosoo,
            companyLogo: privatex_Logo,
        },
        {
            review: `"Well, I'm fully satisfied with their work. also, they are very talented and know their work very well. They can complete any work even before timelimit with greatness"`,
            clientName: 'Noumen Anastasia',
            position: 'CEO, NYC Studio',
            clientImg: NoumenAnastasia,
            companyLogo: NYC,
        },
        {
            review: `"Caliente iTech are outstanding at what they do. They're the experts in this business and I recommend them over anyone else. They make dreams a reality. We're just getting started."`,
            clientName: 'Justin Horrocks',
            position: 'CEO, Annuit Solutions',
            clientImg: JustinHorrocks,
        },
    ];

    return (
        <div className="px-8 mx-auto max-w-7xl">
            <div className="text-center mt-32 mb-20">
                <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                    <span className="text-3xl sm:text-4xl tracking-tight text-white">
                        Client <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                            Success Stories
                        </span>
                    </span>
                    <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                        Explore our client success stories, where individuals share how our products/services made a positive impact on their lives or businesses.
                    </p>
                </div>

                <div className="flex items-center gap-6 md:gap-12 mt-10 md:mt-24 reviewSwiper" data-aos="fade-up" data-aos-duration="900">
                    <div className="swiper-button-prev"></div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={60}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                        modules={[Autoplay, Navigation]}
                    >
                        {DataArr?.map((data, index) => (
                            <SwiperSlide key={index}>
                                <div className="flex flex-col justify-between text-start min-h-[350px]">
                                    <p className='text-white-light'>
                                        {data?.review}
                                    </p>
                                    <div className={`flex ${data?.companyLogo ? 'gap-10' : 'gap-5'}`}>
                                        <div className="client-image min-w-16 max-w-16 relative">
                                            <img
                                                className="rounded-full"
                                                src={data?.clientImg}
                                                alt={data?.clientName}
                                            />
                                            {data?.companyLogo && (
                                                <div
                                                    className="logo absolute bottom-0 -right-5 w-9 h-9 rounded-full bg-no-repeat bg-center bg-black"
                                                    style={{
                                                        backgroundImage: `url(${data?.companyLogo})`
                                                    }}>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex flex-col justify-center gap-1">
                                            <p className='text-base text-white'>{data?.clientName}</p>
                                            <p className='text-sm text-white-light'>{data?.position}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-button-next"></div>
                </div>
            </div>
        </div>
    );
};

export default ClientReviews;
