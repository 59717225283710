import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';

import CoreadyMain from '../../../Assets/Images/SelectedWork/Coready/coready.jpeg';
import Coready1 from '../../../Assets/Images/SelectedWork/Coready/1.jpeg';
import Coready2 from '../../../Assets/Images/SelectedWork/Coready/2.jpeg';

const Coready = () => {

   const caseStudyData = {
      title: 'Coready!',
      content: `Saving the world with Coready! We were facing the epidemic and we all were in this together and to break the chain of the corona infection by making you, your family, friends, and in the end everybody safer, the Coready is specially crafted to enhance the capabilities of social distancing and contact tracking by using the latest cutting edge tech. The good news is it's free! Social Distancing, Safe Zone feature, Privacy, and Anonymity adding all this smart feature to the application makes it outstand and it will automatically give you an alert or notification that you are near to a high-risk or potential corona infected person and you need to be extra cautious. The safe zone alert is based on the contact of the person who is corona infected or in the vicinity of the corona infected person in the past 14 days. Coready! does not provide a guarantee that other person has or will have a corona, it's just put the probability, calculated based on the latest Statistical and AI algorithms.`,
      galleryImages: [
         { url: Coready1 },
         { url: Coready2 },

      ],
      workDetails: `The project was inspired by the situation faced by all humans on the planet and there was which helped our engineers, it was an interesting study by Oxford researchers. The Social Bubble feature was inspired from there, it is repeatedly interacting with the same social contacts, by creating micro-communities. The application has a feature to find out if these social bubbles are violated with a special Social Bubble score. Our experts working remotely from home day and night due to the lockdown yet they were successful in having smooth work management and communication channel. Tricky parts and ups and downs faced as we were not able to use our work lab the testing phase was a huge task but we managed it after all we are engineers, overcoming obstacles is the ability which we all have but we don’t mention it anywhere.`,
      outCome: 'Launching the application in the middle of lookdown in the mid-May month and within a few days it grabbed the attention of local institutes and media houses, the National Institute of technology got very much interested and decided to join the Coready over the good cause and backed in other sorts of way, the media had a very positive view over this and spread the word about it for people’s safety. Coready was successful and safe for people.',
      year: '2020',
      client: 'Coready!',
      services: 'Mobile application Development, BLE Integration',
      keyExpertise: 'Web & Mobile Application Development',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${CoreadyMain})`,
            paddingTop: '35%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Coready;
