import React from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

const Alert = ({ alert, setAlert }) => {

    if (!alert?.show) {
        return null;
    }

    const { type, message } = alert;

    let alertClasses = "flex justify-between mt-8 p-3 rounded-md border ";
    let iconColor = "";

    switch (type) {
        case "success":
            alertClasses += "border-green-500 bg-green-800 bg-opacity-25";
            iconColor = "text-green-500";
            break;
        case "error":
            alertClasses += "border-red-500 bg-red-800 bg-opacity-25";
            iconColor = "text-red-500";
            break;
        case "warning":
            alertClasses += "border-yellow-500 bg-yellow-800 bg-opacity-25";
            iconColor = "text-yellow-500";
            break;
        default:
            alertClasses += "border-gray-500 bg-gray-800 bg-opacity-25";
            iconColor = "text-gray-500";
    }

    return (
        <div className={alertClasses}>
            <p className={iconColor}>{message}</p>
            <div>
                <Icon
                    path={mdiClose}
                    size={1}
                    className={iconColor + " cursor-pointer"}
                    onClick={() => setAlert({ show: false })}
                />
            </div>
        </div>
    );
};

export default Alert;
