import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";

import blockchain_img from "../../../../Assets/Images/Services/BannerImg/blockchain.svg";
import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import btnSwift from '../../../../Assets/Images/Services/TechStack/btnSwift.svg'
import FeaturedSection from "../../Component/FeaturedSection/FeaturedSection";
import feature_1 from "../../../../Assets/Images/Services/FeaturedSection/feature_1.svg";
import feature_2 from "../../../../Assets/Images/Services/FeaturedSection/feature_2.svg";
import feature_3 from "../../../../Assets/Images/Services/FeaturedSection/feature_3.svg";
import feature_4 from "../../../../Assets/Images/Services/FeaturedSection/feature_4.svg";
import feature_5 from "../../../../Assets/Images/Services/FeaturedSection/feature_5.svg";
import feature_6 from "../../../../Assets/Images/Services/FeaturedSection/feature_6.svg";

const Blockchain = () => {

   const techImages = [
      Ethereum,
      Polygon,
      Binance,
      Tron,
      Near,
      QLDB,
      Metamask,
      Hyperledger,
      Solidity,
      Web3JS,
      ERC20,
      btnSwift
   ];

   const featuredImages = [
      feature_1,
      feature_2,
      feature_3,
      feature_4,
      feature_5,
      feature_6
   ];

   const BannerDescData = [
      {
         text: "Introducing Tech Alchemy – your catalyst for company transformations. We specialize in the creation and development of digital products and services, strategically designed to propel real business value and elevate customer experiences.",
      },
   ];

   return (
      <>
         <ServiceBanner
            title="Award winning blockchain agency"
            descData={BannerDescData}
            bannerImage={blockchain_img}
         />
         <FeaturedSection techImages={featuredImages} techDesc="We are trusted by a mix of large companies, brands, as well as the most ambitious startups. Our products have been used by millions and received widespread critical acclaim." />
         <TechStack techDesc={"Whether you're looking to streamline supply chain operations, enhance security in financial transactions, or explore decentralised applications (dApps), we have the expertise to deliver the right solution for you."} techImages={techImages} />
         <SomeOfOurWork dataArr="SpiritSwap" />
         <WorkWithUs mainTitle="Blockchain" title="specialists" desc="We have expertise in blockchain, AI, and emerging technologies to build truly innovative products. Alongside Amazon, Meta, Accenture and Coinbase, we have a portion of the scarce number of blockchain native programmers worldwide. Our dedicated in-house engineers enable us to operate responsively to changing client requirements." />
      </>
   )
}

export default Blockchain;