import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import cybersecurityBanner from "../../../../Assets/Images/Services/BannerImg/cyber-security-banner.svg";

import cybersecurity1 from "../../../../Assets/Images/Services/ServiceDetails/Cybersecurity/cybersecurity-service1.png"
import cybersecurity2 from "../../../../Assets/Images/Services/ServiceDetails/Cybersecurity/cybersecurity-service2.svg"
import cybersecurity3 from "../../../../Assets/Images/Services/ServiceDetails/Cybersecurity/cybersecurity-service3.svg"
import cybersecurity4 from "../../../../Assets/Images/Services/ServiceDetails/Cybersecurity/cybersecurity-service4.png"

import Angular from '../../../../Assets/Images/Services/TechStack/Angular.svg'
import ReactImg from '../../../../Assets/Images/Services/TechStack/React.svg'
import NextJS from '../../../../Assets/Images/Services/TechStack/NextJS.svg'
import Nativescript from '../../../../Assets/Images/Services/TechStack/Nativescript.svg'
import NodeJS from '../../../../Assets/Images/Services/TechStack/NodeJS.svg'
import PHP from '../../../../Assets/Images/Services/TechStack/PHP.svg'
import Typescript from '../../../../Assets/Images/Services/TechStack/Typescript.svg'
import Python from '../../../../Assets/Images/Services/TechStack/Python.svg'
import MongoDB from '../../../../Assets/Images/Services/TechStack/MongoDB.svg'
import Postgre from '../../../../Assets/Images/Services/TechStack/Postgre.svg'
import Redis from '../../../../Assets/Images/Services/TechStack/Redis.svg'
import Express from '../../../../Assets/Images/Services/TechStack/Express.svg'
import Nest from '../../../../Assets/Images/Services/TechStack/Nest.svg'
import KOA from '../../../../Assets/Images/Services/TechStack/KOA.svg'
import AWS from '../../../../Assets/Images/Services/TechStack/AWS.svg'
import GoogleCloud from '../../../../Assets/Images/Services/TechStack/GoogleCloud.svg'
import Lambda from '../../../../Assets/Images/Services/TechStack/Lambda.svg'

const Cybersecurity = () => {

    const BannerDescData = [
        {
            text: "We assist businesses of every size in safeguarding their digital assets and defending against evolving cyber threats.",
        },
        {
            text: "We offer comprehensive security assessments for iOS and Android apps and integrations, ensuring thorough checks. Additionally, we specialize in developing custom solutions for platform security, cryptography, authentication, and storage.",
        },
    ];

    const ServiceDetailsArr = [
        {
            title: "Protect your valuable assets from cyber threats",
            desc: "With our expertise in compliant software development, your cyber security and safety are assured. We possess extensive experience across various industries, such as Finance, Logistics, and Healthcare, where regulatory requirements shape the solutions we build.",
            image: cybersecurity1,
        },
        {
            title: "Comprehensive security solutions",
            desc: "We ensure the security of your cloud and on-premises infrastructures, covering aspects like networks, servers, cryptography, and authorization patterns.",
            image: cybersecurity2,
        },
        {
            title: "Fortifying your infrastructure",
            desc: "We deliver comprehensive network security solutions to safeguard your critical infrastructure and data. Our services range from implementing firewalls and intrusion detection systems to setting up secure VPNs and access controls, ensuring your network is fortified against unauthorized access and data breaches.",
            image: cybersecurity3,
        },
        {
            title: "Robust encryption",
            desc: "In today's digital landscape, safeguarding sensitive data is paramount. We utilize robust encryption techniques to guarantee the confidentiality and integrity of your data, whether it's at rest or in transit. Our encryption solutions provide peace of mind and ensure compliance with industry regulations.",
            image: cybersecurity4,
        },
    ];

    const techImages = [
        Angular,
        ReactImg,
        NextJS,
        Nativescript,
        NodeJS,
        PHP,
        Typescript,
        Python,
        MongoDB,
        Postgre,
        Redis,
        Express,
        Nest,
        KOA,
        AWS,
        GoogleCloud,
        Lambda,
    ];

    return (
        <>
            <ServiceBanner
                title="Cybersecurity"
                descData={BannerDescData}
                bannerImage={cybersecurityBanner}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to integrate" title="robust cybersecurity" />
        </>
    )
}

export default Cybersecurity;