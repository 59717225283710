import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import TechStack from "../../Component/TechStack/TechStack";

import WebDevelopment_IMG from "../../../../Assets/Images/Services/BannerImg/web-development-banner.svg";

import Angular from '../../../../Assets/Images/Services/TechStack/Angular.svg'
import ReactImg from '../../../../Assets/Images/Services/TechStack/React.svg'
import NextJS from '../../../../Assets/Images/Services/TechStack/NextJS.svg'
import Nativescript from '../../../../Assets/Images/Services/TechStack/Nativescript.svg'
import NodeJS from '../../../../Assets/Images/Services/TechStack/NodeJS.svg'
import PHP from '../../../../Assets/Images/Services/TechStack/PHP.svg'
import Typescript from '../../../../Assets/Images/Services/TechStack/Typescript.svg'
import Python from '../../../../Assets/Images/Services/TechStack/Python.svg'
import AWS from '../../../../Assets/Images/Services/TechStack/AWS.svg'
import GoogleCloud from '../../../../Assets/Images/Services/TechStack/GoogleCloud.svg'
import Lambda from '../../../../Assets/Images/Services/TechStack/Lambda.svg'
import TensorFlow from '../../../../Assets/Images/Services/TechStack/TensorFlow.svg'
import EKS from '../../../../Assets/Images/Services/TechStack/EKS.svg'
import ECS from '../../../../Assets/Images/Services/TechStack/ECS.svg'
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";

const WebDevelopment = () => {

    const BannerDescData = [
        {
            text: "We're Tech Alchemy, dedicated to catalyzing company transformations. Our expertise lies in crafting digital products and services that not only drive tangible business value but also elevate customer experiences.",
        },
    ];

    const techImages = [
        Angular,
        ReactImg,
        NextJS,
        Nativescript,
        NodeJS,
        PHP,
        Typescript,
        Python,
        AWS,
        GoogleCloud,
        Lambda,
        TensorFlow,
        EKS,
        ECS,
    ];

    return (
        <>
            <ServiceBanner
                title="Award winning web development agency"
                descData={BannerDescData}
                bannerImage={WebDevelopment_IMG}
            />
            <TechStack techImages={techImages} />
            <SomeOfOurWork dataArr="TackGPS, Hilyte, DBIL" />
        </>
    )
}

export default WebDevelopment;