import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import SmartToken_IMG from "../../../../Assets/Images/Services/BannerImg/smart-token-banner.svg";

import SmartTokensImg01 from "../../../../Assets/Images/Services/ServiceDetails/SmartTokens/SmartTokensImg01.svg"
import SmartTokensImg02 from "../../../../Assets/Images/Services/ServiceDetails/SmartTokens/SmartTokensImg02.svg"
import SmartTokensImg03 from "../../../../Assets/Images/Services/ServiceDetails/SmartTokens/SmartTokensImg03.svg"
import SmartTokensImg04 from "../../../../Assets/Images/Services/ServiceDetails/SmartTokens/SmartTokensImg04.svg"
import SmartTokensImg05 from "../../../../Assets/Images/Services/ServiceDetails/SmartTokens/SmartTokensImg05.svg"
import SmartTokensImg06 from "../../../../Assets/Images/Services/ServiceDetails/SmartTokens/SmartTokensImg06.svg"

import Ethereum from '../../../../Assets/Images/Services/TechStack/Etherum.svg'
import Polygon from '../../../../Assets/Images/Services/TechStack/Polygon.svg'
import Binance from '../../../../Assets/Images/Services/TechStack/Binance.svg'
import Tron from '../../../../Assets/Images/Services/TechStack/Tron.svg'
import Near from '../../../../Assets/Images/Services/TechStack/Near.svg'
import QLDB from '../../../../Assets/Images/Services/TechStack/QLDB.svg'
import Metamask from '../../../../Assets/Images/Services/TechStack/Metamask.svg'
import Hyperledger from '../../../../Assets/Images/Services/TechStack/Hyperledger.svg'
import Solidity from '../../../../Assets/Images/Services/TechStack/Solidity.svg'
import Web3JS from '../../../../Assets/Images/Services/TechStack/Web3JS.svg'
import ERC20 from '../../../../Assets/Images/Services/TechStack/ERC20.svg'
import Blockchain from '../../../../Assets/Images/Services/TechStack/Blockchain.svg'

const SmartTokens = () => {

    const techImages = [
        Ethereum,
        Polygon,
        Binance,
        Tron,
        Near,
        QLDB,
        Metamask,
        Hyperledger,
        Solidity,
        Web3JS,
        ERC20,
        Blockchain,
    ];

    const BannerDescData = [
        {
            text: "Tech Alchemy stands at the forefront of advancing digital service delivery through smart tokens.",
        },
        {
            text: "We've observed the evolution from websites to apps, and now a new paradigm has emerged with smart tokens. At Tech Alchemy, we specialize in unlocking their potential to develop specialty tokens capable of addressing real-world challenges that persist with traditional technologies, utilizing Web3 solutions.",
        }
    ];

    const ServiceDetailsArr = [
        {
            title: "Revolutionising composability: crafting seamless interfaces",
            desc: "The distinctive composability enables users to effortlessly interact with different platforms and services without departing from their initial experience. This paradigm shift transcends the limitations of centralized services, heralding a new era of dynamic and interconnected digital interactions. At Tech Alchemy, we engineer specialty tokens to empower your users to engage with a myriad of experiences seamlessly, liberating them from centralized service constraints while preserving their original context.",
            image: SmartTokensImg01,
        },
        {
            title: "Tokens not just as an asset class, but applications",
            desc: "Tech Alchemy facilitates the enhancement of digital experiences through the development of specialty tokens. These tokens offer interoperability and decentralization, all without necessitating the inclusion of your entire customer base in a public ledger database.",
            image: SmartTokensImg02,
        },
        {
            title: "Seamless interoperability",
            desc: "We specialize in creating specialty tokens with a middleware interface, adding an extra layer of logic. This enables seamless interaction with third-party services, covering countless scenarios without the need for you to establish standards. With our help, your platforms interact smoothly via smart tokens, fostering effortless user engagement. The result? A fluid digital ecosystem where complex bridges are no longer needed.",
            image: SmartTokensImg03,
        },
        {
            title: "Addressing real-world challenges using tailored solutions",
            desc: "Our adept developers craft smart tokens capable of driving various applications, addressing longstanding real-world challenges inherent in traditional (Web2.0) settings. Whether you're a startup, enterprise, or individual, our customized software solutions employing smart tokens are designed to confront your distinctive obstacles directly.",
            image: SmartTokensImg04,
        },
        {
            title: "Limitless innovation: unleashing the possibilities",
            desc: "We engineer software solutions that surpass basic ownership functions, unlocking advanced integration capabilities. With our smart token expertise, you can explore possibilities that were previously beyond reach.",
            image: SmartTokensImg05,
        },
        {
            title: "Conquering functionality, friction & scale",
            desc: "We design and develop smart tokens that not only transmit value but also convey crucial transaction information. This approach tackles functionality, minimizes friction, and directly addresses scalability concerns.",
            image: SmartTokensImg06,
        },
    ];

    return (
        <>
            <ServiceBanner
                title="Smart Tokens"
                descData={BannerDescData}
                bannerImage={SmartToken_IMG}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to build" title="the best smart tokens" />
        </>
    )
}

export default SmartTokens;