import React, { useContext } from 'react';
import { UserContext } from '../../../../Context/UserContext';
import CustomBackground from '../../../../Components/CustomBackground/CustomBackground';
import Button from '../../../../Components/Button/Button';
import line from '../../../../Assets/Images/Services/line.svg';
import bgVideo from '../../../../Assets/Video/BgVideo2.mp4';

const WorkWithUs = ({ mainTitle, title, desc }) => {

   const { setIsContactDrawerOpen } = useContext(UserContext);

   return (
      <div
         className='px-8 sm:px-0 mt-10'
         data-aos="fade-up"
         data-aos-duration="900"
      >
         <CustomBackground
            bgType="video"
            videoSrc={bgVideo}
            bottomGradient={false}
         >
            <div className="py-20 relative mx-auto overflow-hidden">
               <div className='mx-auto max-w-7xl px-4'>
                  <div className="container mx-auto relative z-10">
                     <h2 className="text-4xl font-bold mb-4 text-white">{mainTitle}</h2>
                     <span className='text-4xl mb-4 relative z-10 text-blue-700 font-bold'>
                        {title}
                        <div className="title-line absolute left-0 -bottom-1 z-[-1]">
                           <img
                              src={line}
                              alt="Frequently asked questions"
                              className="w-full h-auto"
                           />
                        </div>
                     </span>
                     <p className="font-medium mt-7 w-full md:w-3/5 text-white">
                        {desc}
                     </p>

                     <div className="mt-10">
                        <Button
                           text="Contact us"
                           btnClass="mt-5 py-3 px-4"
                           btnType="white"
                           onClick={() => setIsContactDrawerOpen(true)}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </CustomBackground>
      </div>
   );
};

export default WorkWithUs;
