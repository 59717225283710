import React from 'react';

const Button = ({ text, btnClass, btnType, ...props }) => {

    return (
        btnType === "white" ?
            <button
                className={`w-fit rounded-md p-2 font-semibold gap-2 bg-white text-black hover:bg-white-light ${btnClass}`}
                {...props}
            >
                {text}
            </button>
            :
            <button
                className={`group inline-flex items-center justify-center rounded-full font-semibold w-max focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2
                                bg-gradient-to-br from-purple to-indigo-light text-white group px-3 py-2 text-xs ${btnClass}`}
                {...props}
            >
                {text}
            </button>
    )
}

export default Button;