import React from 'react';
import Banner from './Banner/Banner';
import AboutUs from './AboutUs/AboutUs';
import Services from './Services/Services';
import SelectedWork from './SelectedWork/SelectedWork';
import GetStartedBanner from './GetStartedBanner/GetStartedBanner';
import ClientReviews from '../../Components/ClientReviews/ClientReviews';
import GetStartedBannerImg from '../../Assets/Images/gif/voice_wave.gif';

const HomePage = () => {

    return (
        <>
            <div
                className='absolute top-0 left-0 w-full h-full bg-center bg-cover opacity-80'
                style={{
                    backgroundImage: `url(${GetStartedBannerImg})`
                }}
            ></div>
            <Banner />
            <AboutUs />
            <Services />
            <SelectedWork />
            <ClientReviews />
            <GetStartedBanner />
        </>
    );
};

export default HomePage;