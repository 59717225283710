import React from 'react';
import squareBgImg from '../../Assets/Images/svg/squareBg.svg';

const CustomBackground = ({ children, className, bgType, bgImage, videoSrc, isBgPattern = true, bottomGradient = true }) => {

    const firstValue = Math.random() * 90;
    const secondValue = firstValue + 10;

    return (
        <div className='flex flex-col overflow-hidden'>
            <div
                className="flex overflow-hidden p-[1px] bg-white bg-opacity-20 rounded-2xl opacity-100 relative"
                style={{ transform: 'perspective(1200px)' }}
            >
                <div className="flex-none inset-0 absolute">
                    <div className="w-full h-full rounded-none overflow-hidden">
                        <div className="borderAnimation absolute -top-full -left-full w-[300%] h-[300%]"
                            style={{
                                backgroundImage: `conic-gradient(transparent ${firstValue}%, rgb(238, 238, 238) ${secondValue}%, transparent 0%)`,
                                // backgroundImage: 'conic-gradient(transparent 0%, rgb(238, 238, 238) 10%, transparent 0%)',
                            }}
                        ></div>
                    </div>
                </div>
                <div className={`flex-1 will-change-transform bg-black rounded-2xl ${className}`}>
                    {
                        bgImage && (
                            <div className="flex-none left-0 top-0 w-full relative z-10">
                                <img
                                    src={bgImage}
                                    alt="backgroundImg"
                                    className='block w-full h-full object-contain object-center'
                                    loading="lazy"
                                />
                            </div>
                        )
                    }
                    {!bgImage && children}
                    {
                        bgType === 'video' ? (
                            <video
                                src={videoSrc}
                                // src="https://framerusercontent.com/assets/Ji57Ag3HAMoPzUpO2zO0v93mBqg.mp4"
                                // src="https://framerusercontent.com/assets/vF2GPLasWTghkImNc4j8Qbho14.mp4"
                                // src="https://framerusercontent.com/assets/Drg6Y9jvvBaet32zzh8OGWHlXE.mp4"
                                loop
                                muted
                                playsInline
                                autoPlay
                                className="absolute w-full h-full object-cover -z-[1] top-0 left-0 opacity-40"
                            />
                        ) : isBgPattern && (
                            <div className="flex-none h-full left-0 opacity-10 overflow-hidden absolute top-0 w-full z-0">
                                <div className="bgPatternDiv">
                                    <img src={squareBgImg} alt="backgroundPattern" />
                                </div>
                            </div>
                        )
                    }
                    {
                        bottomGradient &&
                        <div className="bg-gradient-to-b from-transparent to-black rounded-2xl absolute -left-[1px] -right-[1px] -bottom-[5px] flex-none h-[50px] sm:h-[100px] z-[1]"></div>
                        // <div className="bg-gradient-to-b from-transparent to-black rounded-2xl absolute left-0 right-0 -bottom-[3px] sm:-bottom-[5px] md:bottom-0 flex-none h-[50px] sm:h-[100px] z-[1]"></div>
                    }
                </div>
            </div>
            {bgImage && children}
        </div>
    )
}

export default CustomBackground;