import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import SphynxLabsMain from '../../../Assets/Images/SelectedWork/SphynxLabs/SphynxLabsTransparent.png';
import SphynxLabs1 from '../../../Assets/Images/SelectedWork/SphynxLabs/1.png';
import SphynxLabs2 from '../../../Assets/Images/SelectedWork/SphynxLabs/2.png';

const SphynxLabs = () => {

   const caseStudyData = {
      title: 'Sphynx Labs',
      content: `Sphynx Labs, a true all in one DeFi platform, real-time charts, aggregator, bridges, mobile app, crypto cards, and much more.`,
      galleryImages: [
         { url: SphynxLabs2 },
         { url: SphynxLabs1 },
      ],
      workDetails: "We are proud to have been deeply involved in the Sphynx Labs project, a comprehensive DeFi platform that encompasses real-time charts, data aggregators, bridges, mobile applications, crypto cards, and various other innovative features. Our team dedicated countless hours to developing robust solutions that not only met but exceeded the project's requirements. Through close collaboration with the Sphynx Labs team, we were able to deliver cutting-edge technologies and seamless user experiences across multiple facets of the platform. We are excited to see the impact of our work in the crypto community and look forward to further collaborations with Sphynx Labs as they continue to push the boundaries of decentralized finance.",
      outCome: 'We are delighted to have collaborated with Sphynx Labs on their groundbreaking project. Our team is immensely proud to have contributed to their success story by delivering high-quality solutions tailored to their needs. The leadership team at Sphynx Labs expressed their satisfaction with our work, and we are thrilled to have forged a strong partnership with them. We extend our best wishes to Sphynx Labs for continued success in their endeavors, and we eagerly anticipate the opportunity to work together again on future projects.',
      year: '2021',
      client: 'Sphynx Labs',
      services: 'DeFi, Blockchain, React.js, Mobile application Development, Smart contract Development',
      keyExpertise: 'DeFi, Smart Contract Development, React.js, Mobile application Development, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${SphynxLabsMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-14'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default SphynxLabs;
