import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import prototype_IMG from "../../../../Assets/Images/Services/BannerImg/prototype-banner.png";
import prototypeImg1 from "../../../../Assets/Images/Services/ServiceDetails/Prototyping/prototypeImg1.svg"
import prototypeImg2 from "../../../../Assets/Images/Services/ServiceDetails/Prototyping/prototypeImg2.svg"
import prototypeImg3 from "../../../../Assets/Images/Services/ServiceDetails/Prototyping/prototypeImg3.svg"
import prototypeImg4 from "../../../../Assets/Images/Services/ServiceDetails/Prototyping/prototypeImg4.svg"

const Prototyping = () => {

    const BannerDescData = [
        {
            text: "We provide thorough prototyping services that establish the foundation for successful projects.",
        },
        {
            text: "Combining cutting-edge technology with a user-centric approach, our experienced team brings your ideas to life. Through prototyping, we validate concepts, refine designs, and create tangible representations of your vision.",
        }
    ];

    const ServiceDetailsArr = [
        {
            title: "Transform ideas into reality",
            desc: "Regardless of whether you're a startup or an established enterprise, our prototyping services lay the groundwork for developing innovative and user-friendly solutions.",
            image: prototypeImg1,
        },
        {
            title: "Validate and refine your vision",
            desc: "Visualize the functionalities and flow of your application via interactive prototypes, facilitating early validation and feedback. Iteratively refine these prototypes to align with your vision and gather stakeholder input before advancing to the development phase.",
            image: prototypeImg2,
        },
        {
            title: "Visualise and test ideas",
            desc: "From wireframes to interactive mockups, we swiftly transform ideas into actionable prototypes, enabling you to visualize, test, and iterate on your product prior to commencing development.",
            image: prototypeImg3,
        },
        {
            title: "Accelerate development",
            desc: "With our prototyping services, you can mitigate risks, enhance efficiency, and expedite the development process.",
            image: prototypeImg4,
        },
    ];

    return (
        <>
            <ServiceBanner
                title="Prototyping"
                descData={BannerDescData}
                bannerImage={prototype_IMG}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <SomeOfOurWork dataArr="TackGPS, eTag, Blazar, Dot" />
            <WorkWithUs mainTitle="Work with us to" title="create a prototype" />
        </>
    )
}

export default Prototyping;