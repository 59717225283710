import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import NafterMain from '../../../Assets/Images/SelectedWork/Nafter/NafterTransparent.png'
import Nafter1 from '../../../Assets/Images/SelectedWork/Nafter/1.png';
import Nafter2 from '../../../Assets/Images/SelectedWork/Nafter/1.png';

const Nafter = () => {

   const caseStudyData = {
      title: 'Nafter',
      content: `Introducing Nafter, an online marketplace for creators and fans to buy, sell, mint & collect content NFTs — all in one web app.`,
      galleryImages: [
         { url: Nafter1 },
         { url: Nafter2 },
      ],
      workDetails: 'In the Nafter project, our team has undertaken comprehensive development tasks encompassing the buying, selling, minting, and collection of content NFTs. Leveraging cutting-edge blockchain technology, we have engineered a seamless platform where users can effortlessly engage in NFT transactions, explore unique digital assets, and build their collections with ease. Our focus on user experience and security has led to the creation of a robust ecosystem that empowers creators and collectors alike to participate in the burgeoning NFT marketplace. Through meticulous coding and innovative design, we have crafted a dynamic platform that sets new standards in the digital asset space, offering users a transformative experience in NFT commerce.',
      outCome: "We are delighted to have collaborated on the Nafter project, contributing to its success story and being an integral part of its journey. Our team's dedication and expertise in developing the platform have earned commendation from the Nafter leadership. The CEO expressed satisfaction with our delivered work and expressed eagerness to continue our partnership. We extend our heartfelt wishes for continued success to the Nafter team and eagerly anticipate future collaborations to further enhance their platform and achieve mutual milestones together.",
      year: '2021',
      client: 'Nafter',
      services: 'NFT, Blockchain, React.js, Smart contract Development',
      keyExpertise: 'NFT, Tokenomics, Smart Contract Development, React.js, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${NafterMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Nafter;
