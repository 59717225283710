import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";
import CustomBackground from "../../Components/CustomBackground/CustomBackground";

import Hawkeye from '../../Assets/Images/SelectedWork/Hawkeye/HawkeyeTransparent.png';
import TackGPS from '../../Assets/Images/SelectedWork/TackGPS/TackgpsTransparent_2.png';
import eTag from '../../Assets/Images/SelectedWork/eTag/eTagTransparent.png';
import Blazar from '../../Assets/Images/SelectedWork/Blazar/blazarTransparent.png';
import Dot from '../../Assets/Images/SelectedWork/Dot/dotTransparent.png';
import Hilyte from '../../Assets/Images/SelectedWork/Hilyte/hilyteTransparent.png';
import DBIL from '../../Assets/Images/SelectedWork/DBIL/dbilTransparent.png';
import Coready from '../../Assets/Images/SelectedWork/Coready/CoreadyTransparent.png';
import SpiritSwap from '../../Assets/Images/SelectedWork/SpiritSwap/SpiritswapTransparent.png';
import Nafter from '../../Assets/Images/SelectedWork/Nafter/NafterTransparent.png';
import Degenape from '../../Assets/Images/SelectedWork/Degenape/DegenapeTransparent.png';
import SphynxLabs from '../../Assets/Images/SelectedWork/SphynxLabs/SphynxLabsTransparent.png';
import Multisender from '../../Assets/Images/SelectedWork/Multisender/MultisenderTransparent.png';
import FantomDao from '../../Assets/Images/SelectedWork/FantomDao/FantomDaoTransparent.png';
import Odda from '../../Assets/Images/SelectedWork/Odda/OddaTransparent.png';
import Medisend from '../../Assets/Images/SelectedWork/Medisend/MedisendTransparent.png';

const ImageCard = ({ className, title, desc, img, to, imgClass }) => {

    return (
        <div
            className={`${className}`}
            data-aos="fade-up"
            data-aos-duration="900"
        >
            <CustomBackground>
                <Link
                    className={`flex flex-col group pb-[2.95rem] `}
                    to={to}
                >
                    <img src={img} className="object-cover w-full h-full z-10" alt="project" />
                    {/* <img src={img} className={`w-full z-10 ${imgClass || 'object-cover h-full'}`} alt="project" /> */}
                    <div className="py-4 px-5 z-10">
                        <p className="text-2xl tracking-wider font-semibold text-white">
                            {title}
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 sm:gap-2 sm:items-end justify-between">
                            <p className={`mt-4 text-[15px] max-w-sm md:max-w-lg lg:max-w-xl overflow-hidden line-clamp-2 text-white-light`}>
                                {desc}
                            </p>
                            <div>
                                <Button text="Read More" />
                            </div>
                        </div>
                    </div>
                </Link>
            </CustomBackground>
        </div>
    )
}

const SelectedWork = () => {

    return (
        <>
            <div className="my-10 px-6 sm:px-10 lg:px-8 mx-auto max-w-3xl lg:max-w-7xl">
                <div className="flex flex-col gap-12">

                    <div className="flex flex-col lg:flex-row gap-10">
                        <ImageCard
                            className="md:basis-2/3"
                            title="Hawkeye"
                            desc="Hawkeye as it’s in the name it says it all, a sleek design and provides safety as the new X1 Pro comes with a lot of new features. The application now allows users to track the car on the map and access the video from any corner around the city."
                            img={Hawkeye}
                            to="/selected-work/hawkeye"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="Tack GPS"
                            desc="Tack GPS Tracker, the ultra long-lasting location tracker, locate what you love and keep what matters to you closer all the time. Tack is a groundbreaking standalone location tracker that runs for 1 month on a single charge in a super-compact package!"
                            img={TackGPS}
                            to="/selected-work/tack-gps"
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-10">
                        <ImageCard
                            className="md:basis-1/3"
                            title="eTag"
                            desc="This unique product allows passengers to check-in and tag bags securely removed from the airport for the primary time, completing the digital transformation of the check-in process."
                            img={eTag}
                            to="/selected-work/etag"
                        />
                        <ImageCard
                            className="md:basis-2/3"
                            title="Blazar"
                            desc="Phone to Phone charge or trader data at the speed of 480 Mbps, not only the phone but the user can connect it with the fitness bands, earbuds, smartwatch, wireless headphones, and any other device that needs to charge."
                            img={Blazar}
                            to="/selected-work/blazar"
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-9">
                        <ImageCard
                            className="md:basis-1/3"
                            title="Dot"
                            desc="The IoT is the future, bringing convenience and ease in the life which runs at a good pace and in hurry, we often forget to switch off the appliances and it stricks us when we are on our way to the destination but don’t worry the DOT is here to rescue, "
                            img={Dot}
                            to="/selected-work/dot"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="Hilyte"
                            desc="HiLyte is a Swiss-based startup that aims at providing cheap clean energy for people who still use kerosene for lighting. The hiLyte solutions produce enough power to both charge phone and give light to a room for one night."
                            img={Hilyte}
                            to="/selected-work/hilyte"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="DBIL"
                            desc="Direct Bazaar international Limited, is a India based direct selling company that commenced with solid principles and enduring vision and goal."
                            img={DBIL}
                            to="/selected-work/Dbil"
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-10">
                        <ImageCard
                            className="md:basis-2/3"
                            title="Nafter"
                            desc="Introducing Nafter, an online marketplace for creators and fans to buy, sell, mint & collect content NFTs — all in one web app."
                            img={Nafter}
                            // imgClass="h-[330px] object-contain p-10"
                            to="/selected-work/nafter"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="Degenerate Ape Academy"
                            desc="Deep in the heart of Dingus Forest echoes the sleepless cries of a troop of 10,000 apes. These aren't just regular apes, however. These are degenerate apes."
                            img={Degenape}
                            // imgClass="h-[300px] object-contain p-10"
                            to="/selected-work/degenape"
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-10">
                        <ImageCard
                            className="md:basis-1/3"
                            title="Sphynx Labs"
                            desc="Sphynx Labs, a true all in one DeFi platform, real-time charts, aggregator, bridges, mobile app, crypto cards, and much more."
                            img={SphynxLabs}
                            // imgClass="h-[300px] object-contain p-10"
                            to="/selected-work/sphynx-labs"
                        />
                        <ImageCard
                            className="md:basis-2/3"
                            title="Multisender"
                            desc="Multisender is a website that allows users to send multiple transactions in a single batch on the Ethereum blockchain. This can be particularly useful for tasks like distributing tokens to multiple addresses or conducting airdrops efficiently."
                            img={Multisender}
                            // imgClass="h-[300px] object-contain p-10"
                            to="/selected-work/multisender"
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-9">
                        <ImageCard
                            className="md:basis-1/3"
                            title="Coready!"
                            desc="Saving the world with Coready! We were facing the epidemic and we all were in this together and to break the chain of the corona infection by making you,"
                            img={Coready}
                            to="/selected-work/coready"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="Spirit Swap"
                            desc="SpiritSwap is a decentralized exchange (DEX) on the Fantom Opera Chain. SpiritSwap's design is based on the Uniswap constant-product automated market maker (AMM)."
                            img={SpiritSwap}
                            to="/selected-work/spirit-swap"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="Fantom Dao"
                            desc="Fantom DAO is the Go-to DeFi 2.0 for the Fantom network to grow the Fantom ecosystem and share the profits with the community while utilizing the speed, security, and scalability that Fantom Offers. Welcome to FantomDAO, a magical world where dreams come true."
                            img={FantomDao}
                            to="/selected-work/fantom-dao"
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row gap-10">
                        <ImageCard
                            className="md:basis-2/3"
                            title="ODDA Magazine"
                            desc="issue 22 10 Years Vol 1 OUT NOW."
                            img={Odda}
                            // imgClass="h-[300px] w-[80%] sm:w-[60%] mx-auto object-contain p-10"
                            to="/selected-work/odda"
                        />
                        <ImageCard
                            className="md:basis-1/3"
                            title="Medisend"
                            desc="A convenient tool to make medical file transfer securely and easier. This is an application that marries unparalleled blockchain technology with an access anywhere extremely user friendly interface that allows the transfer of medical documents securely from one Medisend user to another Medisend user within a closed community of medical professionals."
                            img={Medisend}
                            // imgClass="h-[300px] object-contain p-10"
                            to="/selected-work/medisend"
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export default SelectedWork;