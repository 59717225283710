import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

import CustomBackground from '../../../Components/CustomBackground/CustomBackground';
import HawkeyeImg from '../../../Assets/Images/SelectedWork/Hawkeye/Hawkeye.png';
import TackGPSImg from '../../../Assets/Images/SelectedWork/TackGPS/TackGPS.jpeg';
import eTagImg from '../../../Assets/Images/SelectedWork/eTag/eTag.jpeg';
import bgVideo from '../../../Assets/Video/BgVideo1.mp4';

const ViewMoreBtn = () => {
    return (
        <Link
            to="/selected-work"
            className="group inline-flex items-center justify-center rounded font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2
                        bg-gradient-to-br from-purple to-indigo-light text-white group px-5 py-3 text-base"
        >
            View More Projects
            <span className="transform pl-2 text-white transition-transform duration-300 ease-out group-hover:translate-x-1">➞</span>
        </Link>
    )
}

const SelectedWork = () => {

    const [thumbsSwiper, setThumbsSwiper] = useState();
    const [activeIndex, setActiveIndex] = useState(0);

    const DataArr = [
        {
            title: "Hawkeye",
            desc: "Introducing Hawkeye, Caliente iTech's advanced safety app, offering car tracking and citywide video access with the new X1 Pro.",
            img: HawkeyeImg,
        },
        {
            title: "Tack GPS",
            desc: "Introducing Tack GPS Tracker App by Caliente iTech: Revolutionary, long-lasting location tracking for what matters most.",
            img: TackGPSImg,
        },
        {
            title: "eTag",
            desc: "Caliente iTech's app revolutionizes check-in by allowing passengers to remotely check-in and tag bags, eliminating the need for long airport queues after online check-ins.",
            img: eTagImg,
        },
    ];

    return (
        <div className="px-8 mx-auto max-w-7xl">
            <div className="text-center mt-32">
                <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                    <span className="text-3xl sm:text-4xl tracking-tight text-white">
                        Our Legacy in <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                            Projects
                        </span>
                    </span>
                    <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                        Startups and future leaders work with us on inventing appealing, revolutionary, innovative ideas, devices, and products.
                    </p>
                </div>

                <div className="hidden lg:block" data-aos="fade-up" data-aos-duration="900">

                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={50}
                        slidesPerView={3}
                        modules={[Thumbs]}
                        className='selectedWorkSwiper'
                    >
                        {DataArr?.map((data, index) => (
                            <SwiperSlide
                                key={index}
                                onClick={() => setActiveIndex(index)}
                                className={`flex flex-col text-start mt-10 md:mt-24 cursor-pointer ${activeIndex === index ? 'opacity-100' : 'opacity-55 hover:opacity-100'}`}
                            >
                                <p className="mt-3 text-3xl tracking-wide font-medium text-white">
                                    {data?.title}
                                </p>
                                <p className="mt-5 text-base leading-7 text-white-light">
                                    {data?.desc}
                                </p>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className="mt-10 flex justify-center gap-x-6">
                        <ViewMoreBtn />
                    </div>

                    <div className="mt-20">
                        <CustomBackground className="relative py-16 px-14 xl:px-16 overflow-hidden" bgType="video" videoSrc={bgVideo}>
                            <Swiper
                                slidesPerView={1.5}
                                spaceBetween={30}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[Thumbs]}
                                className="selectedWorkSwiperImage"
                            >
                                {
                                    DataArr?.map((data, index) => (
                                        <SwiperSlide
                                            key={index}
                                            className="flex flex-col items-center justify-center"
                                        >
                                            <img className='rounded-2xl' src={data?.img} alt={data?.title} />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </CustomBackground>
                    </div>
                </div>

                <div className="block lg:hidden" data-aos="fade-up" data-aos-duration="900">
                    {
                        DataArr?.map((data, index) => {
                            return (
                                <div className="text-start mt-20" key={index}>
                                    <div className="md:px-24">
                                        <p className="mt-3 text-3xl tracking-wide font-medium text-white">
                                            {data?.title}
                                        </p>
                                        <p className="mt-5 text-base leading-7 text-white-light">
                                            {data?.desc}
                                        </p>
                                    </div>

                                    <div className="mt-10">
                                        <CustomBackground
                                            bgType="video"
                                            videoSrc={bgVideo}
                                        >
                                            <div className="w-fit relative mx-auto p-5 pb-16 flex justify-center items-center rounded-xl">
                                                <img className='rounded-xl h-[200px] sm:h-[300px] object-cover' src={data?.img} alt={data?.title} />
                                            </div>
                                        </CustomBackground>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="mt-10 flex justify-center">
                        <ViewMoreBtn />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SelectedWork;
