import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";

const Chatbot = () => {

    useEffect(() => {
        Crisp.configure(process.env.REACT_APP_CRISP_APP_ID);
        // Crisp.configure("dcf51529-6e7c-4e47-a52f-2158836c066b");
        // Crisp.configure("8479a68d-3205-4d7f-b79b-f367ae69714c");
        // Crisp.setColorTheme("green");
        // Clean up Crisp configuration when the component unmounts
        return () => {
            // Optional: Perform any cleanup or additional actions when the component unmounts
        };
    }, []);

    return null;
};

export default Chatbot;