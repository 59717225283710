import React from 'react';
import { Link } from 'react-router-dom';
import CustomBackground from '../CustomBackground/CustomBackground';

const CaseStudyAdditionalDetails = ({ year, client, services, keyExpertise }) => {

   return (
      <div
         className='my-16 container px-5 sm:px-8 mx-auto max-w-7xl'
         data-aos="fade-up"
         data-aos-duration="900"
      >
         <CustomBackground bottomGradient={false}>
            <section className="work-details grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 lg:gap-7 p-5 sm:p-10 relative z-10">
               <div>
                  <p className='text-2xl tracking-wide font-medium text-white'> YEAR </p>
                  <p className='text-white-light mt-5'>{year}</p>
               </div>
               <div>
                  <p className='text-2xl tracking-wide font-medium text-white'> CLIENT </p>
                  <p className='text-white-light mt-5'>{client}</p>
               </div>
               <div>
                  <p className='text-2xl tracking-wide font-medium text-white'> SERVICES </p>
                  <p className='text-white-light mt-5'>{services}</p>
                  <p className='pt-4 underline'>
                     <Link to="/services"> More about our services </Link>
                  </p>
               </div>
               <div>
                  <p className='text-2xl tracking-wide font-medium text-white'> KEY EXPERTISE </p>
                  <p className='text-white-light mt-5'>{keyExpertise}</p>
                  <p className='pt-4 underline'>
                     <Link to="/expertise"> More about our expertise </Link>
                  </p>
               </div>
            </section>
         </CustomBackground>
      </div>
   );
};

export default CaseStudyAdditionalDetails;
