import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import TechStack from "../../Component/TechStack/TechStack";

import mobileDevelopment_IMG from "../../../../Assets/Images/Services/BannerImg/mobile-development-banner.svg";

import Python from '../../../../Assets/Images/Services/TechStack/Flutter.svg'
import AR1 from '../../../../Assets/Images/Services/TechStack/AR1.svg'
import VR from '../../../../Assets/Images/Services/TechStack/VR.svg'
import IOS from '../../../../Assets/Images/Services/TechStack/IOS.svg'
import android from '../../../../Assets/Images/Services/TechStack/android.svg'
import SomeOfOurWork from "../../Component/SomeOfOurWork/SomeOfOurWork";

const MobileDevelopment = () => {

    const BannerDescData = [
        {
            text: "At Tech Alchemy, we're catalysts for transformation. We specialize in designing and developing digital products and services that deliver tangible business value and enhance customer experiences.",
        },
    ];

    const techImages = [
        Python,
        AR1,
        VR,
        IOS,
        android,
    ];

    return (
        <>
            <ServiceBanner
                title="Award winning mobile app development agency"
                descData={BannerDescData}
                bannerImage={mobileDevelopment_IMG}
            />
            <TechStack techImages={techImages} />
            <SomeOfOurWork dataArr="Hawkeye, TackGPS, eTag, Blazar, Hilyte, DBIL" />
        </>
    )
}

export default MobileDevelopment;