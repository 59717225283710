import React from "react";
import Projects from "../Projects/Projects";
import Award01 from "../../../Assets/Images/png/Awards/Award01.png"
import Award02 from "../../../Assets/Images/png/Awards/Award02.png"
import Award03 from "../../../Assets/Images/png/Awards/Award03.png"
import Award04 from "../../../Assets/Images/png/Awards/Award04.png"

const Banner = () => {

    const AwardArr = [
        Award01,
        Award02,
        Award03,
        Award04,
    ]

    return (
        <div
            className="flex flex-col items-center justify-center mx-auto max-w-7xl px-8 gap-8 my-12 xxs:my-24"
        // style={{
        //     height: "calc(100vh - var(--header-height))",
        // }}
        >
            <div className='text-center'>
                <p className='relative z-10 sm:px-24 md:px-28 lg:px-16 mx-auto max-w-4xl text-3xl md:text-5xl lg:text-7xl font-semibold md:font-medium tracking-tight text-white'>
                    Your dream. Our tech. The next big thing.
                </p>
                <p className="relative mx-auto px-4 mt-5 md:mt-10 max-w-xl md:text-lg font-medium tracking-tight text-white-light">
                    Startups and future leaders work with us on inventing appealing, revolutionary, innovative ideas, devices, and products.
                </p>
            </div>

            <Projects />

            <div className="relative flex flex-col justify-center items-center gap-5 px-5 py-3">
                <p className="relative text-center sm:font-semibold text-lg text-white-light">
                    Accolades
                </p>
                <div className="flex justify-center gap-5 sm:gap-10">
                    {
                        AwardArr?.map((image, index) => {
                            return (
                                <img src={image} alt={image} className="w-auto h-10 md:h-14" key={index} />
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default Banner;