import React from "react";

const Services = () => {

    const ServiceCard = ({ title, desc }) => {
        return (
            <div className="flex flex-col text-start border-t border-slate-dark">
                <p className="mt-6 text-2xl md:text-4xl tracking-wide font-medium text-white">
                    {title}
                </p>
                <p className="mt-5 text-base leading-7 text-white-light">
                    {desc}
                </p>
            </div>
        )
    }

    return (
        <div className="px-8 mx-auto max-w-7xl">
            <div className="text-center mt-32">

                <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                    <span className="text-3xl sm:text-4xl tracking-tight text-white">
                        Explore <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                            Our Services
                        </span>
                    </span>
                    <p className="max-w-2xl mt-4 text-md tracking-tight text-white-light">
                        Caliente iTech team speeds up the working process by deploying smart techniques, coordinating with production and business strategist.
                    </p>
                </div>

                <div className="grid lg:grid-cols-2 items-start justify-center gap-10 mt-10 md:mt-24 w-full" data-aos="fade-up" data-aos-duration="900">

                    <div className="flex flex-col gap-10">
                        <div className="flex flex-col sm:flex-row gap-8">
                            <ServiceCard
                                title="AI & Machine learning"
                                desc="Artificial intelligence can be daunting, and implementing machine learning projects is often slow and costly. At Caliente iTech, we use cutting-edge technology to swiftly deliver valuable insights from your data, making AI and machine learning accessible and efficient."
                            />
                            <ServiceCard
                                title="Internet of Things"
                                desc="In a hyper-connected world, the line between physical and digital realms is fading fast. Our IoT consultants create implementation strategies and application roadmaps for secure IoT solutions. Leveraging expertise, we connect assets, enable real-time cloud communication, and drive analytics for informed decisions."
                            />
                        </div>

                        <div className="flex flex-col sm:flex-row gap-8">
                            {/* <ServiceCard
                                title="Software as a service"
                                desc="Salesforce is a great example of Software as a Service. Due to the benefits of extensive easiness and fastness of installation late charges, the SAAS platform is the best candidate for a market. We provide all solutions for SAAS solutions."
                            /> */}
                            <ServiceCard
                                title="AR & VR"
                                desc="Virtual Reality and Augmented Reality are a powerful medium that allows the business to engage with customers like never before. Our services can guide you through the complexities of that medium and help your business to strategically leverage Virtual, Augmented, and Mixed Reality to gain a competitive edge."
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-10">
                        <ServiceCard
                            title="Blockchain and Cryptocurrency"
                            desc="Blockchain is a disruptive technological transformation akin to the advent of the internet for how businesses transact with one another. From tracking to transferring money, Smart contracts for Insurance, supply chain, and healthcare, Blockchain is transforming every aspect of the business. It’s not limited to any one industry as it spans across industries and applications."
                        />
                        <div className="flex flex-col sm:flex-row gap-8">
                            <ServiceCard
                                title="Big Data & Analytics"
                                desc="Big Data offers valuable insights, but much of it remains unanalyzed. Caliente iTech bridges this gap, providing end-to-end solutions for information management, business intelligence, and analytics, ensuring businesses like yours maximize their data potential."
                            />
                            <ServiceCard
                                title="Product Design"
                                desc="Digital product design combines creative, technical, critical, and strategic thinking to drive innovation through an iterative development process. We serve as your strategic design and innovation partner, leveraging this approach to propel your business forward."
                            />
                            {/* <ServiceCard
                                title="ERP & CRM"
                                desc="The world of ERP is often seen as an ancient world dominated by many big vendors. We are David challenging this Goliaths in this field. We have developed unique methodologies that allow our clients to undertake ERP and business change programs using structured processes that maximize the likelihood of project success by promoting business ownership, minimizing risk, and controlling cost while ensuring that planned business benefits are realized."
                            /> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services;