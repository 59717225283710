import React from "react";
import ServiceBanner from "../../Component/ServiceBanner/ServiceBanner";
import ServiceDetails from "../../Component/ServiceDetails/ServiceDetails";
import TechStack from "../../Component/TechStack/TechStack";
import WorkWithUs from "../../Component/WorkWithUs/WorkWithUs";

import datAnalyticsBanner from "../../../../Assets/Images/Services/BannerImg/data-analytics-banner.svg";

import dataAnalyticsImg1 from "../../../../Assets/Images/Services/ServiceDetails/DataAnalytics/dataAnalyticsImg1.svg"
import dataAnalyticsImg2 from "../../../../Assets/Images/Services/ServiceDetails/DataAnalytics/dataAnalyticsImg2.svg"
import dataAnalyticsImg3 from "../../../../Assets/Images/Services/ServiceDetails/DataAnalytics/dataAnalyticsImg3.png"
import dataAnalyticsImg4 from "../../../../Assets/Images/Services/ServiceDetails/DataAnalytics/dataAnalyticsImg4.svg"
import dataAnalyticsImg5 from "../../../../Assets/Images/Services/ServiceDetails/DataAnalytics/dataAnalyticsImg5.svg"

import NodeJS from '../../../../Assets/Images/Services/TechStack/NodeJS.svg'
import PHP from '../../../../Assets/Images/Services/TechStack/PHP.svg'
import Typescript from '../../../../Assets/Images/Services/TechStack/Typescript.svg'
import Python from '../../../../Assets/Images/Services/TechStack/Python.svg'
import MongoDB from '../../../../Assets/Images/Services/TechStack/MongoDB.svg'
import Postgre from '../../../../Assets/Images/Services/TechStack/Postgre.svg'
import Redis from '../../../../Assets/Images/Services/TechStack/Redis.svg'

const DataAnalytics = () => {

    const BannerDescData = [
        {
            text: "We recognize that data is the lifeblood of any modern organization.",
        },
        {
            text: "Our data analytics solutions empower businesses to unearth valuable insights, make informed decisions, and seize new growth opportunities. Whether you require assistance with data collection, analysis, visualization, or predictive modeling, our development team crafts software solutions to help you extract maximum value from your data assets.",
        },
    ];

    const ServiceDetailsArr = [
        {
            title: "Using data to drive growth and innovation",
            desc: "We collaborate closely with our clients to grasp their distinct business objectives and challenges, enabling us to customize data analytics solutions to their precise needs. From defining key performance indicators (KPIs) to implementing advanced analytics techniques, our aim is to furnish you with actionable insights that fuel meaningful outcomes for your organization.",
            image: dataAnalyticsImg1,
        },
        {
            title: "Empowering your analytics",
            desc: "We oversee the entire data lifecycle, ensuring that the software we build for you incorporates the necessary tools to effectively leverage your data. Whether you're embarking on your data journey or seeking to enhance your existing analytics capabilities, we're here to assist you every step of the way.",
            image: dataAnalyticsImg2,
        },
        {
            title: "Structured and unstructured data",
            desc: "Our project managers offer guidance on data governance, data architecture, and data management best practices. With experience handling both structured and unstructured data, we're equipped to build solutions tailored to your needs, regardless of your starting point.",
            image: dataAnalyticsImg3,
        },
        {
            title: "Advanced analytics & powerful visualisations",
            desc: "We utilize advanced analytics techniques and powerful visualization tools to help you transform raw data into actionable insights. At Tech Alchemy, we merge technical expertise with a profound understanding of business needs, ensuring that we develop solutions to unlock the full potential of your data.",
            image: dataAnalyticsImg4,
        },
        {
            title: "Predictive modeling & ML solutions",
            desc: "Harnessing the power of predictive modeling and machine learning algorithms, we assist you in developing software to anticipate future trends, forecast outcomes, and optimize your business processes.",
            image: dataAnalyticsImg5,
        },
    ];

    const techImages = [
        NodeJS,
        PHP,
        Typescript,
        Python,
        MongoDB,
        Postgre,
        Redis,
    ];

    return (
        <>
            <ServiceBanner
                title="Data Analytics"
                descData={BannerDescData}
                bannerImage={datAnalyticsBanner}
            />
            <ServiceDetails
                detailsArr={ServiceDetailsArr}
            />
            <TechStack techImages={techImages} />
            <WorkWithUs mainTitle="Work with us to unlock new" title="possibilities in data analytics" />
        </>
    )
}

export default DataAnalytics;