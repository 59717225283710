import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import MultisenderMain from '../../../Assets/Images/SelectedWork/Multisender/MultisenderTransparent.png';
import Multisender1 from '../../../Assets/Images/SelectedWork/Multisender/1.png';
import Multisender2 from '../../../Assets/Images/SelectedWork/Multisender/2.png';

const Multisender = () => {

   const caseStudyData = {
      title: 'Multisender',
      content: `Multisender is a website that allows users to send multiple transactions in a single batch on the Ethereum blockchain. This can be particularly useful for tasks like distributing tokens to multiple addresses or conducting airdrops efficiently. By batching transactions, users can save on gas fees and reduce the time it takes to execute multiple transactions individually. It's a handy tool for managing Ethereum transactions in bulk.`,
      galleryImages: [
         { url: Multisender1 },
         { url: Multisender2 },
      ],
      workDetails: "We are thrilled to have been part of the Multisender project, a revolutionary platform that simplifies token and NFT distribution to multiple recipients with just a few clicks. Since February 2018, Multisender has been a trusted tool for thousands of users, enabling them to save time and resources while securely sending tokens. Our team ensured that the smart contracts underwent thorough internal audits for enhanced security. Multisender's intuitive interface allows users to effortlessly send tokens to any number of recipients in minutes, streamlining the airdrop process. With our seamless three-step approach, Multisender guides users through the entire process, making token distribution efficient and hassle-free.",
      outCome: "We are delighted to have collaborated on the Multisender project, which has revolutionized token and NFT distribution with its intuitive platform. Our team is proud to have contributed to their success story, ensuring the platform's efficiency and security. The CEO of Multisender expressed satisfaction with our work, and we are grateful for the opportunity to work with such innovative clients. We wish Multisender continued success in their endeavors and eagerly anticipate future collaborations.",
      year: '2021',
      client: 'Multisender',
      services: 'Blockchain, NFT, React.js, Smart contract Development',
      keyExpertise: 'NFT, Smart Contract Development, React.js, Technical Support',
   };

   return (
      <div>
         <div className="mt-12 md:mt-0 p-0 bg-contain" style={{
            backgroundImage: `url(${MultisenderMain})`,
            paddingTop: '25%',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-24'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   );
};

export default Multisender;
