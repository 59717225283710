import React from 'react';
import CaseStudyDetails from '../../../Components/CaseStudies/CaseStudyDetails';
import CaseStudyAdditionalDetails from '../../../Components/CaseStudies/CaseStudyAdditionalDetails';
import TackGPSMain from '../../../Assets/Images/SelectedWork/TackGPS/TackGPS.jpeg';
import TackGps1 from '../../../Assets/Images/SelectedWork/TackGPS/1.jpeg';
import TackGps2 from '../../../Assets/Images/SelectedWork/TackGPS/2.jpeg';
import TackGps3 from '../../../Assets/Images/SelectedWork/TackGPS/3.jpeg';
import TackGps4 from '../../../Assets/Images/SelectedWork/TackGPS/4.jpeg';

const TackGps = () => {

   const caseStudyData = {
      title: 'Tack GPS',
      content: 'Tack GPS Tracker, the ultra long-lasting location tracker, locate what you love and keep what matters to you closer all the time. Tack is a groundbreaking standalone location tracker that runs for 1 month on a single charge in a super-compact package! There is a wide range of spectacular specs of this device which is remarkable and commendable.',
      galleryImages: [
         { url: TackGps1 },
         { url: TackGps2 },
         { url: TackGps3 },
         { url: TackGps4 },
      ],
      workDetails: 'They were developing a new kind of GPS tracker using the NB-IoT platform. They have made a very small device that can be run on a battery for at least 3 months. Using outperformed Algos and Embedded Software Development expertise they will be able to achieve it. We provided them support in firmware and hardware development. Along with that we also provided them phone application with which the device get paired and enable the user to monitor the location, with minimal UI/UX we delivered a delightful experience',
      outCome: 'We were obliged to work with them on such a project, and so proud to be a part of their success story. The CEO of Tack GPS was impressed by our work delivered to them and was so happy to pair up with us for such a project, we wish such clients a lot more success to their career and look forward to work with them again.',
      year: '2019',
      client: 'Tack GPS',
      services: 'Rapid Experiential Prototyping, Firmware Support, Mobile application Development, Embedded Development',
      keyExpertise: 'Firmware & Embedded Development, Mechanical Engineering, Mobile application Development, Manufacturing Support',
   };

   return (
      <div>

         <div className='mt-12 md:mt-0' style={{
            backgroundImage: `url(${TackGPSMain})`,
            padding: '0',
            paddingTop: '35%',
            backgroundSize: 'contain',
            backgroundPosition: '50%',
         }}></div>

         <div className='relative z-0 text-white'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12 lg:mt-28'>
               <div className='xl:relative'>
                  <div className='mx-auto max-w-3xl '>
                     <CaseStudyDetails {...caseStudyData} />
                  </div>
               </div>
            </div>
            <CaseStudyAdditionalDetails {...caseStudyData} />
         </div>
      </div>
   )
}

export default TackGps