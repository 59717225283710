import React from 'react';

const TechStack = ({ techImages, techDesc }) => {

   return (
      <div className="relative flex justify-center items-center overflow-hidden mx-auto max-w-7xl px-8 py-12 sm:py-24">
         <div className="text-center">
            <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
               <span className="text-3xl sm:text-4xl tracking-tight text-white mb-12 sm:mb-18">
                  Our <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                     Tech
                  </span> Stack
               </span>
               {
                  techDesc &&
                  <p className="font-medium text-white-light mb-7 w-full md:w-4/5">
                     {techDesc}
                  </p>
               }
            </div>
            <div
               className={`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-${Math.min(5, techImages.length)} gap-4`}
               data-aos="fade-up"
               data-aos-duration="900">
               {techImages?.map((imageUrl, index) => (
                  <div key={index} className="flex justify-center items-center">
                     <img
                        src={imageUrl}
                        alt={`TechImage${index + 1}`}
                        className="h-12 sm:h-24 w-auto"
                     />
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default TechStack;
