import React from 'react';
import CustomBackground from '../../../../Components/CustomBackground/CustomBackground';

const FeaturedSection = ({ techImages, techDesc }) => {

   return (
      <div className="relative flex justify-center items-center overflow-hidden">
         <div className="text-center mx-auto max-w-7xl px-8 py-20">
            <CustomBackground>
               <div className="px-5 py-12 sm:py-16 relative z-20">
                  <div className="flex flex-col items-center justify-center" data-aos="zoom-in fade-up">
                     <span className="text-3xl sm:text-4xl tracking-tight text-white font-semibold mb-12 sm:mb-18">
                        <span className="bg-gradient-to-br from-purple to-indigo-light bg-clip-text text-transparent">
                           Featured
                        </span> in and backed by
                     </span>
                     <p className="font-medium text-white-light mb-7 w-full md:w-3/5">
                        {techDesc}
                     </p>
                  </div>
                  <div
                     className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-10 lg:gap-4 mt-5`}
                     data-aos="fade-up"
                     data-aos-duration="900"
                  >
                     {techImages?.map((imageUrl, index) => (
                        <div key={index} className="flex justify-center items-center">
                           <img
                              src={imageUrl}
                              alt={`TechImage${index + 1}`}
                              height="40px"
                              width="90px"
                           // className="h-12 sm:h-24 w-auto"
                           />
                        </div>
                     ))}
                  </div>
               </div>
            </CustomBackground>
         </div>
      </div>
   );
}

export default FeaturedSection;
