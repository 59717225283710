import React, { useRef, useState } from 'react'
import Input from '../../Components/Input/Input';
import Dropdown from '../../Components/Dropdown/Dropdown';
import Loader from '../../Components/Loader/Loader';
import Alert from '../../Components/Alert/Alert';
import LetterImg from '../../Assets/Images/svg/letter-small.svg';
import emailjs from '@emailjs/browser';
import useForm from '../../Hooks/useForm';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../../Components/Button/Button';
import CustomBackground from '../../Components/CustomBackground/CustomBackground';

const JobCategoryOptions = [
    { value: 'full_time', name: 'Full Time' },
    { value: 'internship', name: 'Internship' },
];

const PositionOptions = [
    { value: 'blockchain', name: 'Blockchain Developer' },
    { value: 'embedded', name: 'Embedded developer' },
    { value: 'firmware', name: 'Firmware developer' },
    { value: 'ios', name: 'iOS Developer' },
    { value: 'android', name: 'Android Developer' },
    { value: 'php', name: 'PHP Developer' },
    { value: 'nodejs', name: 'Node Js Developer' },
    { value: 'reactjs', name: 'React Js Developer' },
    { value: 'reactnative', name: 'React Native Developer' },
    { value: 'uiux', name: 'UI/UX Designer' },
    { value: 'bde', name: 'Business Development Executive' },
    { value: 'other', name: 'Other' },
];

const jobOpenings = [
    {
        title: 'Blockchain Developer',
        experience: '3+ Years',
        openings: 1,
    },
    {
        title: 'Business Development Executive (BDE)',
        experience: '0 - 2 Years',
        openings: 2,
    },
    // {
    //     title: 'Android Developer',
    //     experience: '3+ Years',
    //     openings: 1,
    // },
    // {
    //     title: 'PHP Developer',
    //     experience: '3+ Years',
    //     openings: 1,
    // },
];

const Careers = () => {

    const recaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, type: "", message: "" });

    const validateForm = () => {
        let error = {};
        if (!values?.name?.trim()) {
            error.name = "Name is required";
        } else if (!/^[a-z A-Z.]*$/.test(values?.name)) {
            error.name = "Name is invalid";
        }
        if (!values?.email?.trim()) {
            error.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values?.email)) {
            error.email = "Email is invalid";
        }
        // if (!values?.jobCategory) {
        //     error.jobCategory = "Job Category is required";
        // }
        // if (!values?.position) {
        //     error.position = "Position is required";
        // }
        return error;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length === 0) {
            if (isCaptchaVerified) {
                setIsLoading(true);
                try {
                    // Your EmailJS service ID, template ID, and user ID
                    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
                    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
                    const userId = process.env.REACT_APP_EMAILJS_USER_ID;

                    // Send email using EmailJS
                    emailjs.sendForm(serviceId, templateId, e.target, userId)
                        .then((response) => {
                            setAlert({ show: true, type: "success", message: "Thank you for subscribing! You're now on the list to receive our latest job postings directly to your inbox. Stay tuned for exciting opportunities!" });
                            setIsLoading(false);
                            setValues({
                                name: "",
                                email: "",
                            });
                            setErrors({});
                            setIsCaptchaVerified(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            // console.error("Error sending email:", error);
                            setAlert({ show: true, type: "error", message: "There was an error submitting the form." });
                        });
                    // Reset reCAPTCHA component
                    if (recaptchaRef?.current) {
                        recaptchaRef?.current?.reset();
                    }
                } catch (error) {
                    setIsLoading(false);
                    // Handle error (you can show an error message)
                    console.error('Error sending email:', error);
                    setIsLoading(false);
                }
            } else {
                setErrors({ reCaptcha: 'Please complete the reCAPTCHA verification.' });
            }
        } else {
            setErrors(formErrors);
        }
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        isCaptchaVerified,
        setIsCaptchaVerified,
        handleInputChange,
        handleReCaptchaVerify,
        handleSubmit,
    } = useForm(onSubmit);

    return (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">

            <div className="flex flex-col mt-10 lg:flex-row lg:gap-28 lg:mt-16">

                <div className="lg:basis-2/3">
                    <span className="text-3xl tracking-tight text-white sm:text-4xl" data-aos="zoom-in fade-up">
                        We are <span className="text-transparent bg-gradient-to-br from-purple to-indigo-light bg-clip-text">
                            Hiring!
                        </span>
                    </span>
                    <div className="grid grid-cols-1 gap-10 my-10 lg:my-16 sm:grid-cols-2" data-aos="fade-up" data-aos-duration="900">
                        {jobOpenings?.map((job, index) => (
                            <div key={index}>
                                <CustomBackground>
                                    <div className="relative p-6 pb-16 overflow-hidden rounded-xl">
                                        <p className="text-2xl font-medium tracking-wide text-white">{job?.title}</p>
                                        <div className="mt-4 text-white-light">
                                            <ul className='px-6 list-disc'>
                                                <li>Experience: {job?.experience}</li>
                                                <li>Openings: {job?.openings}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </CustomBackground>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="lg:mt-10 lg:basis-1/3">

                    <div className="flex items-center gap-6">
                        <img
                            src={LetterImg}
                            alt="mail"
                            className="w-16 h-16"
                        />
                        <p className="text-xl tracking-wider text-white">
                            Get new job postings delivered to your inbox.
                        </p>
                    </div>
                    <Alert alert={alert} setAlert={setAlert} />
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 mt-10 gap-7">
                            <Input
                                type="text"
                                placeholder="Name"
                                id="name"
                                name="name"
                                value={values?.name || ""}
                                onChange={handleInputChange}
                                required
                                error={errors?.name}
                            />

                            <Dropdown
                                id="jobCategory"
                                name="jobCategory"
                                value={values?.jobCategory || JobCategoryOptions[0]?.name}
                                onChange={(e) => handleInputChange(e, "jobCategory")}
                                Options={JobCategoryOptions}
                                setErrors={errors?.jobCategory}
                            />
                            {
                                errors?.jobCategory &&
                                <p className="text-red-600">{errors?.jobCategory}</p>
                            }
                            <Dropdown
                                id="position"
                                name="position"
                                value={values?.position || PositionOptions[0]?.name}
                                onChange={(e) => handleInputChange(e, "position")}
                                Options={PositionOptions}
                                setErrors={errors?.position}
                            />
                            {
                                errors?.position &&
                                <p className="text-red-600">{errors?.position}</p>
                            }
                            <Input
                                type="email"
                                placeholder="Email"
                                id="email"
                                name="email"
                                value={values?.email || ""}
                                onChange={handleInputChange}
                                required
                                error={errors?.email}
                            />
                        </div>
                        <div className="grid grid-cols-1 mt-10">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                onChange={handleReCaptchaVerify}
                                theme="dark"
                            />
                            {
                                errors?.reCaptcha &&
                                <p className="text-red-600">{errors?.reCaptcha}</p>
                            }

                            <Button
                                text={isLoading ? <Loader /> : "Submit"}
                                btnClass="mt-5"
                                btnType="white"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Careers;